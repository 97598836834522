<template>
<div>
    <p class="tickets-title">آخرین اعلان ها</p>
    <div class="table-container">
        <perfect-scrollbar @ps-y-reach-end="notification" ref="scroll">
            <table>
                <template v-if="AnnouncementList != ''">
                    <tr v-for="(Announcement , index) in AnnouncementList" :key="index">
                        <a href="/notification" @click.prevent="reade(index)">
                            <td :class="{'read' : Announcement.is_read===true}">
                                <img src="@/assets/dote-yellow.png" alt="">
                                {{Announcement.title}}
                            </td>
                        </a>
                    </tr>
                    </template>
            </table>
        </perfect-scrollbar>
    </div>
</div>
</template>

<script>
export default {
    name: 'Announcement',
    components: {

    },
    data() {
        return {
            AnnouncementList: [],
            lastPage: 1,
            page: 0,
        }
    },
    methods: {
        async notification() {
            if (this.page < this.lastPage ) {
                    this.state.loading = true
                    this.page++
                    const res = await this.$axios.get(
                        '/notifications?all', {
                            headers: {
                                'Authorization': `Bearer ${this.$cookies.get('token')}`
                            },
                            params: {
                                size: 10,
                                page: this.page,
                            }
                        }
                    )
                    this.$refs.scroll.$el.scrollTop = 0;
                     if (res) {
                    this.lastPage = res.data.meta.last_page
                    this.AnnouncementList = [...this.AnnouncementList, ...res.data.data]
                }
                    this.state.loading = false
                }

            },
            async reade(index) {
                await this.$axios.patch(
                    `/notifications/${this.AnnouncementList[index].id}`,
                    null, {
                        headers: {
                            'Authorization': `Bearer ${this.$cookies.get('token')}`
                        }
                    }
                )
                this.$router.push('/notification')
            }
        },
        mounted() {
            this.notification()
        }
    }
</script>

<style scoped>
.tickets-title {
    font-weight: 500;
    font-size: 18px;
    color: #DBABA7;
}

table {
    width: 100%;
}

.table-container {
    height: 320px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.table-container::-webkit-scrollbar {
    display: none;
}

.tr-head {
    font-weight: 500;
    font-size: 16px;

    color: rgba(255, 156, 7, 1);
}

tr {
    height: 40px;
    color: #FFFFFF;
    font-size: clamp(10px, 1.5vw, 16px);
}

td {
    padding-right: 20px;
}

tr:nth-child(odd) {
    background: #2F3239;
}

tr:nth-child(even) {
    background: #272A30;
}

.read {
    opacity: 0.7;
}

a {
    color: white;
}

.no-content {
    color: red;
    opacity: 0.7;
}
</style>
