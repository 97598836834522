<template>
<div class="home" v-if="cryptoList">
    <NavbarHome />
    <div v-if="$route.path=='/'" class="home">
        <Baner v-model='cryptoList.data.data' :key='reload' />
        <CryptoBox v-model='cryptoList.data.data' />
        <WhyAdateter />
        <ApplicationLink/>
        <Steps />
        <BlogItems />
        <div class="start-button-div">
            <router-link to="/subscribe">
                <button class="start-button">
                    همین حالا شروع کنید
                </button>
            </router-link>
        </div>
        <Footer />
    </div>
    <FAQ v-if="$route.path=='/FAQ'" />
    <About v-if="$route.path=='/about'" />
    <Contact v-if="$route.path=='/contact'" />
    <Policy v-if="$route.path=='/policy'" />
</div>
</template>

<script>
import Baner from "./Baner.vue"
import CryptoBox from './CryptoBox.vue'
import WhyAdateter from './WhyAdateter.vue'
import Footer from './Footer.vue'
import Steps from './Steps.vue'
import BlogItems from "./BlogItems.vue"
import NavbarHome from './NavbarHome.vue'
import FAQ from './FAQ.vue'
import About from './About'
import Contact from './Contact'
import Policy from './Policy'
import ApplicationLink from './ApplicationLink.vue'
export default {
    name: 'HomePage',
    components: {
        Baner,
        CryptoBox,
        WhyAdateter,
        Footer,
        Steps,
        BlogItems,
        NavbarHome,
        FAQ,
        About,
        Contact,
        Policy,
        ApplicationLink
    },
    data() {
        return {
            cryptoList: '',
            reload: 0
        }
    },
    methods: {
        async personalinfo() {
            this.state.loading = true;
            const res = await this.$axios.get(
                "/users/init", {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            );
            this.state.correction_factor = res.data.data.rank_correction_factor
            this.state.status = res.data.data.status;
            this.state.email = res.data.data.email;
            this.state.mobile = res.data.data.profile.mobile;
        },

        async getCrypto() {
            this.state.loading = true
            this.cryptoList = await this.$axios.get('/markets?status=ACTIVATED&size=500')
            for (var i = 0; i <= this.cryptoList.data.data.length - 1; i++) {
                if (this.cryptoList.data.data[i].code == 'TOMAN') {
                    this.cryptoList.data.data.splice(i, 1)
                }

            }
            this.reload = 1
            this.state.loading = false
        },

    },
     mounted() {
         if(this.$cookies.get('userStatus') && this.$cookies.get('token')) {
            this.personalinfo()
        }
        this.getCrypto()
    },
}
</script>

<style scoped>
/* .home {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  } */
.home {
    background-color: #2F3239;
    background-image: url('../../assets/bg-pattern.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    row-gap: 80px;
}

.start-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-button {
    border: 0;
    background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
    border-radius: 5px;
    width: 160px;
    height: 45px;
    font-weight: 500;
    font-size: 16px;
}
</style>
