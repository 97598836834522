import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "../components/HomePage/HomePage.vue"
// import About from "../components/HomePage/About.vue"
// import Policy from "../components/HomePage/Policy.vue"
// import Contact from "../components/HomePage/Contact.vue"
// import FAQ from "../components/HomePage/FAQ.vue"
import Login from "../components/LoginSub/Login.vue"
import Authentication from "../components/Authentication/Authentication.vue"
import Profile from "../components/Profile/Profile.vue"
import PaymentCallback from '../components/Profile/PaymentCallback/PaymentCallback.vue'
// import OTPLogin from '../components/LoginSub/OTPLogin.vue'
Vue.use(VueRouter)
const that = Vue.prototype

const routes = [
    {
      path:"/panel/payment/online/:id",
      name:'Back',
      component : PaymentCallback ,
      props : true
    },
    {
      path: '/auth/:provide/callback',
      component: {
        template: '<div class="auth-component"></div>'
      }
    },
    {
        path: '/',
        name:'HomePage',
        component: HomePage
    },
    {
      path: '/about',
      name:'About',
      component: HomePage
    },
    {
      path: '/contact',
      name:'Contact',
      component: HomePage
    },
    {
      path: '/policy',
      name:'Policy',
      component: HomePage
    },
    {
      path: '/FAQ',
      name:'FAQ',
      component: HomePage
    },
    {
      path: '/login',
      name:'Login',
      component: Login
    },
    {
      path: '/subscribe',
      name:'Subscribe',
      component: Login
    },
    {
      path: '/register-email',
      name:'RegisterEmail',
      component: Login
    },
    {
      path: '/forgot-email',
      name:'ForgetPass',
      component: Login
    },
    {
      path: '/forget-confirm',
      name:'ForgetConfirm',
      component: Login
    },
    {
      path: '/change-pass',
      name:'ChangePass',
      component: Login
    },
    {
      path: '/email-otp',
      name:'EmailOtp',
      component: Login
    },
    {
      path: '/login-otp',
      name:'LoginOtp',
      component: Login
    },
    {
      path: '/finnotech',
      name:'Finnotech',
      component: Login,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus') == 'OTP_DONE') {
          next('/dashboard')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus') == 'SECONDARY_AUTH_DONE'){
          next()
        }
        else if(that.$cookies.get('token')) {
          next('/authentication')
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/authentication',
      name:'Authentication',
      component: Authentication,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next()
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next()
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next('/dashboard')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next('/dashboard')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next('/dashboard')
        }

        else {
          next('/login')
        }
      }
    },
    {
      path: '/fino-verifying',
      name:'FinoVerifying',
      component: Login,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token')) {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/dashboard',
      name:'Dashboard',
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/account',
      name:'Account',
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/exchange',
      name:'Exchange',
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/wallet',
      name:'Wallet',
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/security',
      name:'Security',
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/invte-friends',
      name:"InviteFriends",
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/history',
      name:'History',
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/support',
      name:"Support",
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '/notification',
      name:'Notification',
      component: Profile,
      beforeEnter: (to , from , next) => {
        if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='PRIMARY_AUTH_DONE') {
          next('/authentication')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='SECONDARY_AUTH_DONE') {
          next('/finnotech')
        }
        else if(that.$cookies.get('token') && that.$cookies.get('userStatus')=='OTP_DONE') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='CONFIRMED_IDENTITY') {
          next()
        }
        else if (that.$cookies.get('token') && that.$cookies.get('userStatus')=='ACCEPTED') {
          next()
        }
        else {
          next('/login')
        }
      }
    },
    {
      path: '*',
      redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }, 500)
    })
}
})


// router.beforeEach( (to , from , next) => {
//   if(that.state.toke) {
//     if(that.state.loading) {
//       return next()
//     }
//     else {
//       return next({name:'Dashboard'})
//     }
//   }
// }
// )

export default router
