<template>
    <div class="login">
        <div class="login-form-title">ورود</div>
        <div>
            <hr/>
        </div>
        <form @submit.prevent="send">
            <label for="">
                ایمیل
                <input type="text" v-model="data.email"/>
                <p class="error-p" v-if="emailError">
                    <!-- <img class="error-img" src="@/assets/error.png" alt=""> -->
                    {{ emailError }}
                </p>
            </label>
            <label for="password">
                رمزعبور
                <button class="show-pass" id="password" @click="showPassword">
                    <img class="show-pass-image" src="@/assets/showpass.png"/>
                </button>
                <input class="password" :type="type" v-model="data.password"/>
                <p class="error-p" v-if="passError">
                    {{ passError }}
                </p>
            </label>
            <router-link to="/forgot-email">
                <p class="forget-a"><a href="">فراموشی رمز عبور</a></p>
            </router-link>
            <button class="login-btn" type="submit" :disabled="!data.email || !data.password">
                ورود
            </button>
            <p class="sub-link">
                حساب کاربری ندارید؟
                <a class="link-p" href="/subscribe" @click.prevent="$router.push('/subscribe')">
                    ثبت نام کنید
                </a>
            </p>
        </form>
        <!-- <div>
            <div class="google-btn" @click.prevent="AuthProvider('google')">
                <img src="@/assets/google.png" alt="" />
                ورود با حساب گوگل
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "HomePage",
    components: {},
    data() {
        return {
            type: "password",
            btnText: "Show Password",
            errorText: "",
            passError: "",
            emailError: "",
            data: {
                email: "",
                password: "",
            },
        };
    },
    methods: {
        AuthProvider(provider) {
            // var bb = this
            this.$auth.authenticate(provider).then(response => {
                this.SocialLogin(response)

            }).catch(err => {
                console.log({
                    err: err
                })
            })

        },
        async SocialLogin(response) {
            await this.$cookies.set('token', response.data.data.token)
            let token = await this.$cookies.get('token')
            let axios = require('axios')
            const userStatus = await axios.get('/users/init', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            await this.$cookies.set('userStatus', userStatus.data.data.status)
            this.$router.push('/authentication')
        },
        showPassword(e) {
            if (this.type === "password") {
                this.type = "text";
                this.btnText = "Hide Password";
            } else {
                this.type = "password";
                this.btnText = "Show Password";
            }
            e.preventDefault();
        },
        async send() {
            
            this.state.loading = true
            await this.checkEmail();
            // await this.check2Pass()
            try {
                const res = await this.$axios.post(
                    "/signin",
                    this.data
                );
                if (res.status == 200) {
                    this.$cookies.set('token', res.data.data.token)
                    this.$cookies.set('email', this.data.email)
                    this.forOtp = res.data.data
                    this.$emit('sendToParent', this.forOtp)
                    await this.$router.push("/login-otp");
                }

            } catch (err) {
                const res = err.response || err.toJSON();
                this.passError = res.data.message;
            }
            this.state.loading = false
        },
        // async check2Pass() {
        //     if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
        //         this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
        //         // this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
        //         console.log(this.passError)
        //         // return Promise.reject('wrong pass : ' + this.data.password)
        //     } else {
        //         this.passError = ''
        //     }
        // },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.emailError = "فرمت پست الکترونیکی معتبر نیست";
                // this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                // return Promise.reject('repeat failed : ' + this.data.email)
            } else {
                this.emailError = "";
            }
        },
        getData() {
            this.data.osType = this.$detectOs();
            this.data.browser = this.$detectBrowser() || "CHROME";
            this.data.deviceType = this.$detectDevice();
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped>
.login,
form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.login-form-title {
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
}

hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(90deg, black, white, black);
}

label,
input {
    width: 100%;
    /* background: #212225; */
    border-radius: 5px;
    border: 0;
}

label {
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    position: relative;
    display: block;
}

input {
    height: 40px;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.04);
}

.password {
    text-align: left;
    padding: 0 10px 0 40px;
    direction: ltr;
}

.login-btn {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
    border: 0;
    font-weight: 500;
    font-size: 16px;
}

.sub-link {
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
}

.link-p {
    text-decoration-line: underline;
    color: #53acff;
}

.google-btn {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    background: transparent;
    color: #ffffff;
    height: 50px;
    cursor: pointer;
}

.forget-a {
    margin: 0;
    text-align: left;
}

.forget-a > a {
    text-decoration-line: underline;
    color: #ffffff;
    font-weight: normal;
    font-size: 12px;
}

.show-pass {
    border: 0;
    background: transparent;
    position: absolute;
    left: 5px;
    top: 35px;
    cursor: pointer;
}
</style>
