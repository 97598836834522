<template>
<div class="authen-part" :key="reload">
    <DeleteBank :id="id" @success="banks" v-if="deleteModal" @close="deleteModal = false" />
    <div class="title">
        <p>اطلاعات بانکی</p>
    </div>
    <div class="describe">
        <p>
            شما حداکثر شماره 10 کارت بانکی را میتوانید ثبت نمایید. توجه فرمایید که
            کارت های ثبت شده باید متعل به صاحب همین حساب کاربری باشد ، در غیر
            اینصورت تایید نخواهند شد.
        </p>
    </div>
    <div class="inputs bank-inputs">
        <label for="">
            شماره کارت
            <input type="text" v-model="BanksForPost.card_number" @input="BanksForPost.card_number = $p2e(BanksForPost.card_number);dash()" />
            <p class="error-p">
                {{ cardErr }}
            </p>
        </label>

        <label for="">
            شماره شبا
            <input type="text" v-model="BanksForPost.sheba" @input="BanksForPost.sheba = $p2e(BanksForPost.sheba)" />
            <p class="error-p">
                {{ shebaErr }}
            </p>
        </label>
        <label for="">
            نام بانک
            <input type="text" v-model="BanksForPost.bankName" disabled />
            <p class="error-p">
                {{ ccountErr }}
            </p>
        </label>
        <div>
            <button v-if="!edit" class="add-bank" @click="addBank" :disabled="!BanksForPost.account_number || !BanksForPost.sheba || !BanksForPost.card_number">افزودن کارت</button>
            <button v-else class="add-bank" @click="editFun">اعمال تغییرات</button>
        </div>
    </div>
    <div class="table-container">
        <table>
            <tr class="head">
                <td class="name-head">بانک</td>
                <td class="card-head">شماره کارت</td>
                <td class="shaba-head">شماره شبا</td>
                <!-- <td class="account-head">شماره حساب</td> -->
                <!-- <td>ویرایش</td> -->
                <!-- <td v-if="BanksData.length > 1">حذف</td> -->
            </tr>
            <tr v-for="(BankInfo, index) in BanksData" :key="index">
                <td>
                    <div class="name">
                        <img class="bank-image" :src="BankInfo.logo" alt="" />
                        <!-- <span v-html="BankInfo.logo"></span> -->
                        {{banksName[BankInfo.card_number ? BankInfo.card_number.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'}}
                    </div>
                </td>
                <td class="cardNumber">
                    {{ BankInfo.card_number }}
                </td>
                <td class="shaba">
                    {{ BankInfo.sheba }}
                </td>
                <!-- <td class="account">
                    {{ BankInfo.account_number }}
                </td> -->
                <!-- <td>
                    <img class="bank_edit" src="@/assets/editcredit.png" alt="" @click="toEdit(index)">
                </td> -->
                <!-- <td class="trash" @click="deletBank(BankInfo.id)" v-if="BanksData.length > 1">
                    <img src="@/assets/trash.png" alt="" />
                </td> -->
            </tr>
        </table>
    </div>
</div>
</template>

<script>
import DeleteBank from '../../Modals/DeleteBank.vue';
export default {
    name: "BankInformation",
    components: {
        DeleteBank
    },
    props: {
        Banks: Array,
    },
    data() {
        return {
            id: "",
            reload: false,
            cardErr: "",
            shebaErr: "",
            ccountErr: "",
            deleteModal: false,
            edit: false,
            editId: '',
            BanksData: [],
            BanksForPost: {
                card_number: "",
                sheba: "",
                account_number: "",
                bankName:""
            },
            errorText: "",
            banksName: {
                '603799': 'بانک ملی ایران',
                '589210': 'بانک سپه',
                '627648': 'بانک توسعه صادرات',
                '207177': 'بانک توسعه صادرات',
                '627353': 'بانک تجارت',
                '585983': 'بانک تجارت',
                '627961': 'بانک صنعت و معدن',
                '603770': 'بانک کشاورزی',
                '639217': 'بانک کشاورزی',
                '628023': 'بانک مسکن',
                '627760': 'پست بانک ایران',
                '502908': 'بانک توسعه تعاون',
                '627412': 'بانک اقتصاد نوین',
                '622106': 'بانک پارسیان',
                '639194': 'بانک پارسیان',
                '627884': 'بانک پارسیان',
                '502229': 'بانک پاسارگاد',
                '639347': 'بانک پاسارگاد',
                '639599': 'بانک قوامین',
                '504706': 'بانک شهر',
                '502806': 'بانک شهر',
                '603769': 'بانک صادرات',
                '610433': 'بانک ملت',
                '991975': 'بانک ملت',
                '589463': 'بانک رفاه',
                '502938': 'بانک دی',
                '639607': 'بانک سرمایه',
                '627381': 'بانک انصار',
                '505785': 'بانک ایران زمین',
                '636214': 'بانک آینده',
                '636949': 'بانک حکمت ایرانیان',
                '621986': 'بانک سامان',
                '639346': 'بانک سینا',
                '606373': 'بانک قرض الحسنه مهر',
                '627488': 'بانک کارآفرین',
                '502910': 'بانک کارآفرین',
                '505416': 'بانک گردشگری',
                '639370': 'بانک مهر اقتصاد',
                '628157': 'موسسه اعتباری توسعه',
                '505801': 'موسسه اعتباری کوثر',
                '504172': ' بانک رسالت'
            }
        };
    },
    methods: {
        //     addBank() {
        //       if(this.name && this.cardNumber && this.shaba) {
        //            this.Banks.push({
        //                     "image":require('../../assets/saman.png'),
        //                     "name":this.name,
        //                     "cardNumber":this.cardNumber,
        //                     "shaba":this.shaba,
        //                     "account":this.shaba,
        //                 })
        //       }

        //   },
        // deletRow(index) {
        //     console.log("Removing", index);
        // this.Banks.splice(index, 1);
        // },
        dash() {
            this.BanksForPost.bankName = this.banksName[this.BanksForPost.card_number ? this.BanksForPost.card_number.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
            // this.data.bankAccounts[e].cardNumber = this.$separator(this.data.bankAccounts[e].cardNumber)
           
        },
        cardNumber() {

        },
        toEdit(e) {
            this.edit = true
            console.log(this.BanksData[e]);
            this.BanksForPost.card_number = this.BanksData[e].card_number
            this.BanksForPost.account_number = this.BanksData[e].account_number
            this.BanksForPost.sheba = this.BanksData[e].sheba
            this.editId = this.BanksData[e].id
        },
        async editFun() {
            this.edit = false
            this.state.loading = true
            await this.$axios.patch(
                '/accounts/' + this.editId,
                this.BanksForPost, {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.banks();
            this.BanksForPost.card_number = "";
            this.BanksForPost.sheba = "";
            this.BanksForPost.account_number = "";
            this.state.loading = false
            this.state.success = true
        },
        async deletBank(index) {
            // this.state.loading = true
            this.id = index
            this.deleteModal = true
            // try {
            //   await this.$axios.delete(
            //   "/accounts/" + index,
            //   { headers: { Authorization: `Bearer ${this.$cookies.get('token')}` } }
            // );
            // this.state.loading = false
            // this.state.success = true
            // }
            // catch(err) {
            //   const error = err.response || err.toJSON();
            //   console.log(error)
            // }
            // this.banks();
            // this.reload = !this.reload
        },
        async addBank() {
            this.state.loading = true
            try {
                await this.$axios.post(
                    "/accounts",
                    this.BanksForPost, {
                        headers: {
                            Authorization: `Bearer ${this.$cookies.get('token')}`
                        }
                    }
                );
                this.banks();
                this.BanksForPost.card_number = "";
                this.BanksForPost.sheba = "";
                this.BanksForPost.account_number = "";

                this.cardErr = ''
                this.shebaErr = ''
                this.ccountErr = ''
                this.state.loading = false
                this.state.success = true
                this.reload = !this.reload
                this.$emit('fromBank', this.BanksData)
            } catch (err) {
                const res = err.response || err.toJSON();
                this.errorText = res.data.message;
                this.cardErr = res.data.errors.card_number[0]
                this.shebaErr = res.data.errors.sheba
                this.ccountErr = res.data.errors.account_number
            }
            this.state.loading = false
        },
        // async deletBank() {
        //     const bank = await this.$axios.get("https://adatether.6.vistadev.ir/api/accounts",
        //          {headers: {'Authorization': `Bearer ${this.state.token}`}})
        // },
        async banks() {
            const bank = await this.$axios.get(
                "/accounts", {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            );
            this.BanksData = bank.data.data;
            this.$emit('fromBank', this.BanksData)
            this.reload = !this.reload
        },
    },
    mounted() {
        this.banks();

    },
};
</script>

<style lang="scss" scoped>
.authen-part {
    border-radius: 5px;
    background: #1d1f23;

    .title {
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .describe {
        font-weight: normal;
        font-size: clamp(12px, 2vw, 14px);
        text-align: right;
        color: #ffffff;
        width: 95%;
        margin: auto;
    }

    .bank-inputs {
        border-bottom: 1px solid #2f3239;
        padding-bottom: 20px;
    }

    .inputs {
        display: flex;
        width: 95%;
        margin: auto;
        column-gap: 100px;
        // align-items: center;
        margin-top: 30px;
        padding-bottom: 30px;
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .table-container {
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .table-container::-webkit-scrollbar {
        display: none;
    }
}

table {
    width: 95%;
    margin: auto;
    text-align: center;
    min-width: 600px;
}

tr {
    font-weight: 500;
    font-size: 16px;
    height: 60px;
}

tr.head {
    color: rgba(255, 176, 7, 1);
}

tr .name,
.shaba,
.cardNumber,
.account {
    color: #ffffff;
}

tr .name {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

tr .name-head {
    width: 100px;
}

.trash {
    cursor: pointer;
}

.bank-image {
    width: 30px;
    height: 30px;
}

.add-bank {
    background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
    border-radius: 5px;
    width: 122px;
    height: 40px;
    border: none;
    margin-top: 30px;
    font-weight: 600;
}

label {
    flex-basis: 200px;
    flex-grow: 1;
}

.bank_edit {
    width: 40px;
    cursor: pointer;
}
</style>
