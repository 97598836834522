import Vue from 'vue';

Vue.use(require('vue-cookies'))

const that = Vue.prototype
const state = Vue.observable({
    token: localStorage.token || Vue.$cookies.get('token') || false,
    email:"",
    status:"",
    loading: false,
    success : false,
    price:{},
    authenErr : false,
    reload:false,
    correction_factor:{},
    mobile : '',
    method:'',
    setting_value:'',
})

that.state = state
export default state