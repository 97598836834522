<template>
<transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal" :key="selectedCoin[0]">
            <!-- <img @click="closeModale" class="close-icon" src="@/assets/close-icon.svg" alt=""> -->
            <span class="message-title">
            </span>
            <div class="exchange-inputs-container">
                <div class="buy-sell-form">
                    <div class="buy-sell-form" v-if="!codeReceived">
                        <label for="" class="coin-type-label">نوع ارز
                            <div class="selected-crypto">
                                <span v-if="selectedCoin!=''">{{selectedCoin[selectedWallet.id].coin.label}}</span>
                            </div>
                        </label>
                        <label for="" class="coin-type-label"> شبکه
                            <div class="selected-crypto">
                                <!-- <img v-if="selected.length!=0" :src=cryptoImages[selected.code].img alt="" /> -->
                                <span v-if="selectedNets != ''">{{ selectedNets }}</span>
                                <span v-if="selectedNets == '' && nets!=''">{{nets[0].name}}</span>
                                <!-- <span>{{ selected.code }}</span> -->
                            </div>
                            <img class="drop-img coin-drop" src="@/assets/caretdown.png" alt="" @click="showCoins" />

                            <div class="search-coins" v-if="coinsDropdown">
                                <div class="drop-row" v-for="(crypto, index) in nets" :key="index" @click="select(index)">
                                    <!-- <img :src="cryptoImages[crypto.coin.code].img" alt="" /> -->
                                    <span class="drop-name" @click="seletctNet(index)">{{ crypto.name }}</span>
                                </div>
                            </div>
                        </label>
                        <label for="">
                            مقدار مورد نظر
                            <input type="text" v-model="tradeData.amount" />
                        </label>
                        <label for="">
                            آدرس کیف پول
                            <input type="text" v-model="withdrawal_data.address ">
                        </label>

                        <label for="" v-if="selectedNets.memo!='' || nets[0].memo!=''" :class="{'disable_memo' : !memo_disable}">
                            <label for="" class="memo-lable">کد ممو
                                <input type="checkbox" @change="memo_disable = !memo_disable">
                            </label>
                            <input type="text" v-model="withdrawal_data.memo" @input="withdrawal_data.memo = $p2e(withdrawal_data.memo)" :disabled="!memo_disable">
                        </label>
                    </div>
                    <div class="withdraw_info" v-if="codeReceived">
                        <div>
                            <span class="info_title">
                                نام ارز
                            </span>
                            <span class="info_value">
                                {{selectedCoin[selectedWallet.id].coin.label}}
                            </span>
                        </div>
                        <div>
                            <span class="info_title">
                                شبکه
                            </span>
                            <span class="info_value">
                                <span v-if="selectedNets != ''">{{ selectedNets }}</span>
                                <span v-if="selectedNets == '' && nets!=''">{{nets[0].name}}</span>
                            </span>
                        </div>
                        <div>
                            <span class="info_title">
                                مقدار
                            </span>
                            <span class="info_value">
                                {{tradeData.amount}}
                            </span>
                        </div>
                        <div>
                            <span class="info_title">
                                آدرس کیف پول
                            </span>
                            <span class="info_value">
                                {{withdrawal_data.address}}
                            </span>
                        </div>
                        <div>
                            <span class="info_title">
                                کد ممو
                            </span>
                            <span class="info_value">
                                <span v-if="withdrawal_data.memo">{{withdrawal_data.memo}}</span>
                                <span v-else>-</span>
                            </span>
                        </div>
                    </div>
                    <label for="" v-if="codeReceived">
                        <span v-if="state.setting_value=='EMAIL'">
                            کد ارسال شده به ایمیل خود را وارد نمایید
                        </span>
                        <span v-if="state.setting_value=='SMS'">
                            کد ارسال شده به شماره موبایل خود را وارد نمایید
                        </span>
                        <span v-if="state.setting_value=='GOOGLE'">
                            کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید.
                        </span>
                        <input type="text" v-model="withdrawal_data.code" @input="withdrawal_data.code = $p2e(withdrawal_data.code)">
                        <p v-if="state.setting_value=='EMAIL'">
                            <TimerBase @finish="resendBtn=true" :timerName="'WithdrawEmail'" :add="5" ref="timer" /> ثانیه
                        </p>
                        <p v-if="state.setting_value=='SMS'">
                            <TimerBase @finish="resendBtn=true" :timerName="'WithdrawSms'" :add="2" ref="timer" /> ثانیه
                        </p>
                    </label>

                    <p class="form-text" v-if="selectedCoin!=''">
                        حداقل تعداد خرید :{{ selectedCoin[selectedWallet.id].min_amount.toLocaleString() }}
                        <span class="form-text-amount">

                        </span>
                    </p>
                    <p class="form-text" v-if="selectedCoin!=''">
                        حداکثر تعداد خرید :{{ selectedCoin[selectedWallet.id].max_amount.toLocaleString() }}
                        <span class="form-text-amount">
                        </span>
                    </p>
                </div>
            </div>
            <p class="error-p">
                {{crypto_network_idErr}}
            </p>
            <p class="error-p">
                {{addressErr}}
            </p>
            <p class="error-p">
                {{amountErr}}
            </p>
            <p class="error-p">
                {{errorText}}
            </p>
            <div class="btns">

                <button class="deposit-btn" @click="depositReq" v-if="codeReceived && resendBtn==false">
                    برداشت {{selectedCoin[selectedWallet.id].coin.label}}
                </button>
                <button class="deposit-btn" v-if="receiveCode" @click="getCode" :disabled="!!readOnly">
                    <span v-if="readOnly">{{readOnly}}</span>
                    <span v-else> دریافت کد دوعاملی</span>
                </button>
                <button class="deposit-btn" v-if="resendBtn" @click="resendCode">
                    ارسال مجدد
                </button>
                <button @click="$emit('closeModal')" class="cancel_btn">
                    انصراف
                </button>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import TimerBase from '../../Tools/TimerBase.vue'
export default {
    name: 'WithdrawalModal',
    components: {
        TimerBase
    },
    props: [
        'value', 'selectedCoinWallet'
    ],
    data() {
        return {
            close: false,
            memo_disable: false,
            resendBtn: false,
            cryptoList: [],
            coinsDropdown: false,
            priceData: this.state.price,
            selected: {},
            totalPrice: 1,
            selectedNets: "",
            depositData: {},
            netId: null,
            errorText: '',
            transactionId: null,
            addressErr: "",
            amountErr: "",
            crypto_network_idErr: "",
            selectedWallet: "",
            withdrawal_data: {
                payment_method: "",
                amount: "",
                address: "",
                memo: "",
                crypto_network_id: this.netId,
                coin_id: "",
                code: "",
            },
            tradeData: {
                market_id: null,
                type: "",
                amount: 0,
                // address: "$2y$10$XkPl1cihsoWSG3fBi8DNS",
                // network_id: null,
            },
            nets: [],
            information: [{
                wage: 321321321,
                minBuy: 0.7725,
                maxBuy: 5,
            }, ],
            selectedId: '3',
            selectedCoin: [],
            receiveCode: true,
            codeReceived: false,
            count: 60,
        }
    },
    computed: {
        readOnly() {
            let error = ''
            if (!this.tradeData.amount) {
                error = 'مقدار را وارد کنید'
            } else if (!this.withdrawal_data.address) {
                error = 'آدرس کیف پول را وارد کنید'
            } else if (this.$S2N(this.tradeData.amount) > this.$S2N(this.selectedCoin[this.selectedWallet.id].max_amount)) {
                error = 'حداکثر مقدار رعایت نشده'
            } else if (this.$S2N(this.tradeData.amount) < this.$S2N(this.selectedCoin[this.selectedWallet.id].min_amount)) {
                error = 'حداقل مقدار رعایت نشده'
            }
            return error
        }
    },
    methods: {
        closeModale() {
            this.$emit('closeModal', this.close)
        },
        async select(index) {
            this.selected = this.cryptoList[index];
            this.selected_for_price = this.selectedCoin[this.selectedWallet.id].coin.code;

            this.coinsDropdown = false;
            this.tradeData.market_id = this.cryptoList[index].id
        },
        showCoins() {
            this.coinsDropdown = !this.coinsDropdown;
        },
        async getNets() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/' + this.selectedWallet.id + '/networks?status=ACTIVATED&withdraw_status=ACTIVATED', {
                headers: {
                    'Authorization': `Bearer ${this.$cookies.get('token')}`
                }
            })
            this.nets = res.data.data
            this.netId = (res.data.data[0] ? this.netId = res.data.data[0].id : 0)
            this.state.loading = false
        },
        seletctNet(index) {
            this.selectedNets = this.nets[index].network
            this.netId = ''
            this.netId = this.nets[index].id
            this.coinsDropdown = false
        },
        async depositReq() {
            this.state.loading = true
            this.withdrawal_data.payment_method = 'CRYPTO'
            this.withdrawal_data.amount = this.$S2N(this.tradeData.amount)
            this.withdrawal_data.coin_id = this.selectedWallet.id
            this.withdrawal_data.crypto_network_id = this.netId
            try {
                await this.$axios.post(
                    '/transactions/type/withdraw',
                    this.withdrawal_data, {
                        headers: {
                            'Authorization': `Bearer ${this.$cookies.get('token')}`
                        }
                    }
                )
                // this.transactionId = res.data.data.id
                this.$emit('closeModal')
                this.state.success = true
            } catch (err) {
                const error = err.response || err.toJSON();
                this.errorText = error.data.message
                if (error.data.errors) {
                    this.addressErr = error.data.errors.address[0]
                }
                if (error.data.message.includes('احراز')) {
                    this.state.authenErr = true
                }
                if (error.data.message.includes('موجودی')) {
                    this.$emit('openCredit')
                }
            }
            this.state.loading = false
        },
        async getCode() {
            this.state.loading = true
            this.receiveCode = false
            this.codeReceived = true
            await this.$axios.post(
                "/transactions/otp",
                null, {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.state.loading = false
            this.inputCode()
        },
        resendCode() {
            this.receiveCode = false
            this.codeReceived = true
            this.resendBtn = false

            this.getCode()
            this.$refs.timer.resetTimer()
            // this.inputCode()
        },
        inputCode() {
            if (this.count > 0) {
                this.timeOut = setTimeout(() => {
                    this.count--
                    this.inputCode()
                }, 1000)
            }
        },
        async getCrypto() {
            const res = await this.$axios.get(
                "/markets?size=500"
            );
            this.cryptoList = res.data.data;
            this.state.loading = false;
        },
    },
    created() {
        window.addEventListener("click", (event) => {
            if (!event.target.closest(".coin-drop , .search-coins")) {
                this.coinsDropdown = false;
            }
            if (!event.target.closest(".nets-drop")) {
                this.coinNets = false;
            } else {
                return true
            }
        });
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    async mounted() {
        this.selectedWallet = this.selectedCoinWallet
        await this.getCrypto()
        this.cryptoList.forEach(element => {
            this.selectedCoin[element.coin_id] = element

        })

        this.getNets()
        this.selected_for_price = this.selectedCoin[this.selectedWallet.id].coin.code;
    }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 400px;
    min-height: 600px;
    height: auto;
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    overflow: scroll;
}

.close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.message-title {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
}

.inputs-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
    column-gap: 16px;
    margin-top: 35px;
}

.deposit-btn {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    background: transparent;
    border: none;
    color: white;
    background: #FF2E21;
}

.btns {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    width: 100%;
    align-items: center;
}

.coin-type-label {
    position: relative;
}

.coin-type-label>img {
    position: absolute;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.selected-crypto {
    height: 40px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    column-gap: 5px;
}

.form-text {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin: 0;
    font-size: clamp(14px, 1vw, 16px);
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
}

.form-text-amount {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    direction: ltr;
}

.buy-sell-form {
    // width: 90%;
    // margin: 0 auto;
    /* display: grid; */
    row-gap: 8px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 10px 0;
}

.exchange-inputs-container {
    width: 100%;
    // height: 520px;
    // background: #2f3239;
    // background: #2f3239;
}

.search-coins {
    min-height: 10px;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #3A3D44;
    border-radius: 5px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0 10px;
}

.search-coins::-webkit-scrollbar {
    display: none;
}

.myInput-container {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
    padding: 0 10px;
}

#myUL {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

#myUL li a {
    border: 1px solid #ddd;
    margin-top: -1px;
    /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
}

#myUL li img {
    width: 25px;
    height: 25px;
}

#myUL li a:hover:not(.header) {
    background-color: #eee;
}

.drop-row {
    display: flex;
    column-gap: 10px;
    width: 250px;
    height: 30px;
    align-items: center;
    border: none;
    /* padding: 0 10px; */
    /* margin-right: 20px; */
}

.drop-row-search {
    background: #2f3239;
    /* border-radius: 5px; */
    display: flex;
    align-items: center;
    border: none;
}

.drop-row-ex:nth-child(even) {
    background-color: #1d1e23;
}

.drop-row-ex:nth-child(odd) {
    background-color: #151619;
}

.drop-row-ex img {
    width: 25px;
    height: 25px;
}

.drop-row-ex {
    display: flex;
    column-gap: 10px;
    width: 200px;
    height: 40px;
    align-items: center;
    border: none;
    padding: 0 10px;
    /* background-color: rgba(255, 255, 255, 0.04); */
}

#myInput {
    background-image: url("../../../assets/search.png");
    background-position: right;
    background-repeat: no-repeat;
    width: 100%;
    height: 25px;
    font-size: 12px;
    position: sticky;
    top: 0;
    background-color: #2f3239;
    border-radius: 5px;
    color: white;
    padding-right: 20px;
    border: none;
    margin: 5px 0;
}

.selected-img {
    width: 25px;
}

.disable_memo {
    opacity: 0.5;
}

.memo-lable {
    display: flex;
    /* justify-content: stretch; */
    align-items: center;
    /* justify-content: revert; */
    flex-direction: row-reverse;
    justify-content: flex-end;

    input {
        width: 20px;
    }
}

.withdraw_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    // row-gap: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid white;

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: white;
        height: 40px;
        flex-wrap: wrap;

        .info_title {
            opacity: 0.7;
        }

        .info_value {
            font-weight: bold;
        }
    }
}

.cancel_btn {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid #FF2E21;
    color: #FF2E21;
    background: transparent;
}
</style>
