<template>
    <div class="authen-part personal">
        <div class="title">
            <p>مشخصات فردی</p>
        </div>
        <div class="describe">
            <p>
                برای تایید مشخصات ، مطابق توضیحات و موارد خواسته شده عمل کنید. در صورت بروز هرگونه سوال میتوانید با پشتیبانی در ارتباط باشید.
            </p>
        </div>
        <div class="inputs" >
            <label for="">
                نام 
                
                <!-- <input type="text" v-if="value.mame != ''" v-model="value.name" @input="sendData" :disabled="$route.path=='/account'"> -->
                <input type="text" v-model="info.name" @input="sendData" :disabled="$cookies.get('userStatus')=='ACCEPTED' || $cookies.get('userStatus')=='CONFIRMED_IDENTITY'">
                <p class="error-p" >
                    {{sendError.name}}
                </p>
                
            </label>
            <label for="">
                 نام خانوادگی
                
                <!-- <input type="text" v-if="value.mame != ''" v-model="value.name" @input="sendData" :disabled="$route.path=='/account'"> -->
                <input type="text" v-model="info.lastname" @input="sendData" :disabled="$cookies.get('userStatus')=='ACCEPTED' || $cookies.get('userStatus')=='CONFIRMED_IDENTITY'">
                <p class="error-p" >
                    {{sendError.lastname}}
                </p>
                
            </label>
            <label for="">
                کد ملی 
                <input type="text" v-model="info.national_code" @input="sendData" :disabled="$cookies.get('userStatus')=='ACCEPTED' || $cookies.get('userStatus')=='CONFIRMED_IDENTITY'">
                <p class="error-p" >
                    {{sendError.national_code}}
                </p>
            </label>
            <label for="">
                تاریخ تولد
                <date-picker format="YYYY-MM-DD"
                    display-format="jYYYY-jMM-jDD"
                     v-model="info.birthday" :disabled="$cookies.get('userStatus')=='ACCEPTED' || $cookies.get('userStatus')=='CONFIRMED_IDENTITY'"></date-picker>
                <img class="calender" src="@/assets/calendar.png" alt="">
                <p class="error-p" >
                    {{sendError.birthday}}
                </p>
            </label>
        </div>
    </div>
</template>
<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
export default {
    name:"PersonalInformation",
    components:{
        datePicker: VuePersianDatetimePicker
    },
    props:{
        value:Object ,
        sendError:Object
    },
    data() {
        return {
            // personData:this.personal
            birthday:'',
            err : this.sendError,
            info : this.value,
        }
    },
    
    // model:{
    //     props:'personal'
    // },
    methods:{
        
        sendData() {
            // console.log('sendPersonal' , this.info);
            // this.info = this.$J2G(this.birthday)
            this.info.national_code = this.$p2e(this.info.national_code)
            this.value = this.info
        },
        // setDate() {
        //     this.info.birthday = this.$J2G(this.birthday)
        // }
        
        // setData() {
        //     this.err = 'aa'
        // }
        // toPrent() {
        //     this.$emit('childData' , this.personData)
        // }
    },
}
</script>
<style lang="scss" scoped>

    .authen-part {
        border-radius: 5px;
        background: #1D1F23;
            .title {
                text-align: center;
                color: rgba(255, 255, 255, 0.6);
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
            .describe {
                font-weight: normal;
                font-size: clamp(12px , 2vw , 14px);
                text-align: right;
                color: #FFFFFF;
                width: 95%;
                margin: auto;
            }
            .inputs {
                display: flex;
                width: 95%;
                margin: auto;
                column-gap: 100px;
                align-items: center;
                margin-top: 30px;
                padding-bottom: 30px;
                flex-wrap: wrap;
                row-gap: 20px;
                    .calender {
                        position: absolute;
                        top: 35px;
                        left: 10px;
                    }
            }
    }
      label {
        flex-basis: 200px;
        flex-grow: 1;
        }  
</style>