<template>
<div class="login">
    <div class="login-form-title">تاییدیه فینوتک</div>
    <div>
        <hr />
    </div>
    <!-- <form @submit.prevent="send"> -->
    <div class="form">
        <div>
            <label for="">
                شماره موبایل
                <input type="text" v-model="data.mobile" disabled />
            </label>
            <label for="">
                کد ملی
                <input type="text" v-model="data.national_id" disabled>
            </label>
        </div>
        <div>
            <label for="">
                <span class="second">{{ this.second }}</span>
                کد ارسال شده به شماره
                <input type="text" @input="data.code = $p2e(data.code)" v-model="data.code" />
            </label>
            <p class="error-p" v-if="ErrorText.length">
                {{ErrorText}}
            </p>
        </div>
        <div class="btn-container">

            <button type="button" v-if="second==0" class="login-btn" @click="RefinnoReq">دریافت مجدد کد</button>
            <button v-else type="submit" class="login-btn" @click="send">تایید کد</button>
            <button class="return" @click="goBack">
                بازگشت به احراز هویت
            </button>
        </div>
    </div>
    <!-- </form> -->
</div>
</template>

<script>
export default {
    name: "Finnotech",
    components: {},
    data() {
        return {
            second: 120,
            //   emailCode:null
            data: {
                mobile: null,
                code: null,
                national_id: null
            },
            Reqdata: {
                mobile: null
            },
            ErrorText: ""
        };
    },
    methods: {
        inputCode(e) {
            //   while(this.second > 0) {
            setTimeout(() => {

                while (this.second > 0) {

                    this.second -= 1

                    this.inputCode()

                }
            }, 1000);

            //    }
            if (this.second == 0) {
                this.reSend()
            }
            e.preventDefault();
            // e.inputCode()

            // alert("kljk")
        },
        async getInfo() {

            this.state.loading = true
            const res = await this.$axios.get("/authentication", {
                headers: {
                    'Authorization': `Bearer ${this.$cookies.get('token')}`
                }
            })
            // console.log(res);
            this.data.mobile = res.data.data.mobile
            this.Reqdata.mobile = res.data.data.mobile
            this.data.national_id = res.data.data.national_code
            this.state.loading = false
            this.finnoReq()
        },
        async finnoReq() {
            this.state.loading = true
            await this.$axios.post(
                "https://anido.ir/auth/api/otp-token-request",
                this.Reqdata, {
                    headers: {
                        'Authorization': `Bearer ${this.$cookies.get('token')}`
                    }
                });
            this.state.loading = false
        },
        async RefinnoReq() {
            
            this.state.loading = true
            await this.$axios.post(
                "https://anido.ir/auth/api/otp-token-request",
                this.Reqdata, {
                    headers: {
                        'Authorization': `Bearer ${this.$cookies.get('token')}`
                    }
                });
            this.state.loading = false
            this.second = 120
            this.inputCode()
        },
        async send() {
            this.state.loading = true
            try {
                await this.$axios.post(
                    "https://anido.ir/auth/api/confirm-otp",
                    this.data, {
                        headers: {
                            'Authorization': `Bearer ${this.$cookies.get('token')}`
                        }
                    });
                const userStatus = await this.$axios.get(
                    '/users/init', {
                        headers: {
                            'Authorization': `Bearer ${this.$cookies.get('token')}`
                        }
                    }
                )
                this.$cookies.set('userStatus', userStatus.data.data.status)
                this.state.loading = false
                this.state.success = true
                this.$router.push("/dashboard");
            } catch (err) {
                const res = err.response || err.toJSON();
                this.ErrorText = res.data.message;
            }
            this.state.loading = false
        },
        async reSend() {

            try {
                //         const res = await this.$axios.post(
                //     "https://adatether.6.vistadev.ir/api/resend-otp",
                //     this.data
                //   );
                //   if (res.data.type == 'success') {
                //     this.$router.push("/change-pass");
                //   }
            } catch (err) {
                const res = err.response || err.toJSON();
                console.log(res.data.errors.email);
                this.ErrorText = res.data.errors.email;
            }
        },
        goBack() {
            this.$router.push('/authentication')
        }
    },
    mounted() {
        // this.data.email = this.state.email;
        this.getInfo()
        this.inputCode()

        // this.finnoReq()

    },
};
</script>

<style scoped>
.login,
.form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.login-form-title {
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
}

hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(90deg, black, white, black);
}

label,
input {
    width: 100%;
    /* background: #212225; */
    border-radius: 5px;
    border: 0;
}

label {
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    position: relative;
}

input {
    height: 40px;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.04);
}

.login-btn {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
    border: 0;
    font-weight: 500;
    font-size: 16px;
}

.sub-link {
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
}

.link-p {
    text-decoration-line: underline;
    color: #53acff;
}

.google-btn {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    background: transparent;
    color: #ffffff;
    height: 50px;
}

.forget-a {
    margin: 0;
    text-align: left;
}

.forget-a>a {
    text-decoration-line: underline;
    color: #ffffff;
    font-weight: normal;
    font-size: 12px;
}

.second {
    border: 0;
    background: #2b2d30;
    position: absolute;
    width: 45px;
    left: 1px;
    top: 26px;
    height: 37px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.return {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ff9c07;
    background: transparent;
    color: #ff9c07;
}

.btn-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
</style>
