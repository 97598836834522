<template>
<div class="exchange">
    <Sell v-model="tradeData" v-if="SellModal" @close="SellModal = false" />

    <div class="exchange-title">
        <p>معاملات</p>
    </div>
    <div class="excahnge-table">
        <div class="exchange-inputs">
            <div class="exchange-inputs-title">
                <div class="buy-sell">
                    <div :class="{ 'active-buy': this.buy, 'deActive-buy': this.sell }" @click="activeBuy">
                        خرید
                    </div>
                    <div :class="{ 'active-sell': this.sell, 'deActive-sell': this.buy }" @click="activeSell">
                        فروش
                    </div>
                </div>
            </div>
            <div class="exchange-inputs-container">
                <div class="buy-sell-form">
                    <label for="" class="coin-type-label">نوع ارز
                        <div class="selected-crypto coin-drop" @click="showCoins">
                            <!-- <img v-if="selected.length!=0" :src=cryptoImages[selected.code].img alt="" /> -->
                            <span>{{ selected.label }}</span>
                            <span>{{ selected.code }}</span>
                        </div>
                        <img class="drop-img coin-drop" src="@/assets/caretdown.png" alt="" @click="showCoins" />

                        <div class="search-coins" v-if="coinsDropdown">
                            <div class="myInput-container">
                                <input type="text" id="myInput" title="Type in a name" v-model="searchCoin" />
                            </div>
                            <ul id="myUL">
                                <li>
                                    <div class="drop-row" v-for="(crypto, index) in filterCoin" :key="index" @click="select(crypto.coin_id)">
                                        <img :src="crypto.coin.logo" alt="" />
                                        <span class="drop-name">{{ crypto.coin.label }}</span>
                                        <span class="drop-ENname">{{ crypto.coin.code }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </label>

                    <label for="">
                        مقدار مورد نظر
                        <input type="text" v-model="tradeData.amount" @input="calcPrice" />
                        <p class="error-p">
                            {{ErrorText.amount[0]}}
                        </p>
                    </label>
                    <label for="">
                        مبلغ کل معامله
                        <input type="text" v-model="totalPrice" disabled />
                    </label>
                    <p class="form-text">
                        موجودی <span v-if="buy && credit">{{credit['TOMAN'].label}}</span> <span v-if="sell ">{{selected.label}}</span> شما:
                        <span class="form-text-amount">

                            <span v-if="buy && credit"> {{$toLocal($prettifyPrice(credit['TOMAN'].credit))}}</span>
                            <span v-else-if="!buy && credit[selected_for_price]">{{ credit[selected_for_price].code }} {{credit[selected_for_price].credit}}</span>
                            <span v-else>
                                0
                            </span>
                        </span>

                    </p>
                    <p class="form-text">
                        حداقل تعداد <span v-if="buy">خرید</span> <span v-if="sell">فروش</span> {{ selected.label }}:
                        <span class="form-text-amount" :key="information.minBuy">
                            {{ setMin }}
                            <span>{{ selected.code }}</span></span>
                    </p>
                    <p class="form-text">
                        حداکثر تعداد <span v-if="buy">خرید</span> <span v-if="sell">فروش</span> {{ selected.label }}:
                        <span class="form-text-amount" :key="information.maxBuy">
                            {{ $toLocal(information.maxBuy)}} {{ selected.code }}
                        </span>
                    </p>
                    <button v-if="buy" class="buy-bit-btn" @click="trade" :disabled="!!readonly">
                        <span v-if="!readonly">خرید {{ selected.label }}</span>
                        <span v-else>{{readonly}}</span>
                    </button>
                    <button v-if="sell" class="sell-bit-btn" @click="trade" :disabled="!!readonly">
                        <span v-if="!readonly"> فروش {{ selected.label }}</span>
                        <span v-else>{{readonly}}</span>
                    </button>
                </div>
            </div>
        </div>
        <CryptoList />
    </div>
    <div class="orders">
        <Orders :addOrder='add' />
    </div>
</div>
</template>

<script>
import Sell from "./Sell.vue";
import Orders from "./Orders/Orders.vue";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
import CryptoList from './CryptoList.vue'
export default {
    name: "Exchange",
    components: {
        Orders,
        Sell,
        CryptoList
    },
    data() {
        return {
            searchCoin: '',
            priceData: '',
            markets_list: [],
            cryptoList: this.value,
            coinsDropdown: false,
            buy: true,
            sell: false,
            SellModal: false,
            BuyModal: true,
            coinNets: false,
            amountBuy: "",
            priceBuy: "",
            walletBuy: "",
            netIndex: null,
            selectedNet: "",
            selected: '',
            selected_for_price: '',
            nets: {},
            chart: {},
            chartOb: {},
            change: 0,
            trend: [],
            totalPrice: null,
            credit: 0,
            generalErr: "",
            selectedOrderId: '',
            ErrorText: {
                amount: ''
            },
            add: true,
            information: {
                minBuy: 0,
                maxBuy: 0,
                min_notional: 0,
            },
            tr_heade: [{
                    title: "رمز ارز",
                },
                {
                    title: "تغییرات",
                },
                {
                    title: "قیمت خرید از ما",
                    subTitle: "تومان",
                },
                {
                    title: "قیمت فروش به ما",
                    subTitle: "تومان",
                },
                {
                    title: "نمودار",
                },
            ],
            tradeData: {
                market_id: null,
                type: "",
                amount: 0,
                // address: "$2y$10$XkPl1cihsoWSG3fBi8DNS",
                // network_id: null,
            },
        };
    },
    props: ["value"],
    computed: {
        setMin() {
            let min = 0
            if (this.buy && this.priceData[this.selected_for_price]) {
                if (this.$S2N(this.information.minBuy) < this.information.min_notional / this.priceData[this.selected_for_price].tomanBuying) {
                    min = this.$prettifyPrice((this.information.min_notional) / (this.priceData[this.selected_for_price].tomanBuying))
                } else {
                    min = this.$prettifyPrice(this.information.minBuy)
                }
            } else if (this.buy && this.priceData[this.selected_for_price]) {
                if (this.$S2N(this.information.minBuy) < this.information.min_notional / this.priceData[this.selected_for_price].tomanSelling) {
                    min = this.$prettifyPrice((this.information.min_notional) / (this.priceData[this.selected_for_price].tomanSelling))
                } else {
                    min = this.$prettifyPrice(this.information.minBuy)
                }
            }
            return min
        },
        readonly() {
            let error = ''
            if (this.buy) {
                if (!this.$S2N(this.tradeData.amount)) {
                    error = 'مقدار را وارد کنید'
                } else if (this.$S2N(this.tradeData.amount) > this.$S2N(this.information.maxBuy)) {
                    error = 'مقدار بیش از حد مجاز است'
                } else if (this.$S2N(this.tradeData.amount) < this.$S2N(this.information.minBuy)) {
                    error = 'مقدار کمتر از حد مجاز است'
                } else if (this.$S2N(this.tradeData.amount) < this.$S2N(this.information.min_notional / this.priceData[this.selected_for_price].tomanSelling)) {
                    error = 'مقدار کمتر از حد مجاز است'
                } else if (this.$S2N(this.totalPrice) > this.$S2N(this.credit['TOMAN'].credit)) {
                    error = 'موجودی کافی نمیباشد'
                }
            } else {
                if (!this.$S2N(this.tradeData.amount)) {
                    error = 'مقدار را وارد کنید'
                } else if (this.$S2N(this.tradeData.amount) > this.$S2N(this.information.maxBuy)) {
                    error = 'مقدار بیش از حد مجاز است'
                } else if (this.$S2N(this.tradeData.amount) < this.$S2N(this.information.minBuy)) {
                    error = 'مقدار کمتر از حد مجاز است'
                } else if (this.$S2N(this.tradeData.amount) < this.$S2N(this.information.min_notional / this.priceData[this.selected_for_price].tomanBuying)) {
                    error = 'مقدار کمتر از حد مجاز است'
                } else if (this.credit[this.selected_for_price]) {
                    if (this.$S2N(this.totalPrice) > this.$S2N(this.credit[this.selected_for_price].credit)) {
                        error = 'موجودی کافی نمیباشد'
                    }
                } else if (!this.credit[this.selected_for_price]) {
                    error = 'موجودی کافی نمیباشد'
                }
            }
            return error
        },
        filterCoin() {
            let filteredCoin
            if (this.searchCoin) {
                filteredCoin = this.markets_list.filter(a => a.coin.label.includes(this.searchCoin))
            } else filteredCoin = this.markets_list
            return filteredCoin
        },
    },
    methods: {
        activeBuy() {
            if (!this.buy) {
                this.buy = true;
                this.sell = false;
                this.calcPrice()
            } else return true;
        },
        activeSell() {
            if (!this.sell) {
                this.sell = true;
                this.buy = false;
                this.calcPrice()
            } else return true;
        },
        async select(index) {
            let selectedCoin = {}
            selectedCoin = this.cryptoList.find(a => a.coin_id == index)
            this.selected = selectedCoin.coin;
            this.selected_for_price = selectedCoin.coin.code;
            this.information.minBuy = selectedCoin.final_min_amount
            this.information.maxBuy = selectedCoin.final_max_amount
            this.information.min_notional = selectedCoin.min_notional
            this.coinsDropdown = false;
            this.tradeData.market_id = selectedCoin.id
            this.calcPrice()
            // this.netIndex = this.markets_list.data.data[index].coin.id;
            // console.log('list',this.markets_list,this.cryptoList);
            // const net = await this.$axios.get(
            //   "http://adatether.6.vistadev.ir/api/markets/" +
            //     this.netIndex +
            //     "/networks"
            // );
            // this.nets = net.data.data;
        },
        async markets() {
            const res = await this.$axios.get(
                "/markets?status=ACTIVATED&size=500"
            );
            this.markets_list = res.data.data
            this.cryptoList = this.markets_list

            this.selected = this.cryptoList[0].coin;
            this.selected_for_price = this.cryptoList[0].coin.code;
            this.information.minBuy = this.cryptoList[0].final_min_amount
            this.information.maxBuy = this.cryptoList[0].final_max_amount
            this.tradeData.market_id = this.cryptoList[0].id
        },
        async priceUSDT() {
            await this.$axios.get(
                "/dollar-prices/last"
            );
            // console.log("USDT", res);
            // this.state.price['USDT'].buying=res.data.data.price
        },
        selectNet(index) {
            this.selectedNet = this.nets[index].name;
            // console.log(this.nets[index].name)
        },
        showNets() {
            this.coinNets = !this.coinNets;
        },
        SellModalFun() {
            if (this.$S2N(this.tradeData.amount) && this.priceSell && this.walletSell) {
                this.SellModal = true;
            }
        },
        BuyModalFun() {
            if (this.amountBuy && this.priceBuy && this.walletBuy) {
                this.$emit("childToParentBuy", {
                    modal: this.BuyModal,
                    crypto: this.selected,
                });
            }
        },
        showCoins() {
            this.coinsDropdown = !this.coinsDropdown;
        },
        async chartData() {
            this.chart = await this.$axios.get(
                "/weekly-prices"
            );

            this.chart.data.data.forEach((element) => {
                this.chartOb[element.coin.code] = element.prices;
            });
            // console.log("aaaaa=>>>", this.chartOb);
            for (let key in this.chartOb) {
                this.trend[key] = Object.values(this.chartOb[key]);
            }
            ++this.change;
            // console.log("trend", this.trend);
            // console.log("ob", this.chartOb);
        },
        calcPrice() {
            if (this.tradeData.amount) {
                this.tradeData.amount = this.$toLocal(this.$p2e(this.tradeData.amount))
                if (this.sell) {
                    this.totalPrice = this.$toLocal(this.$prettifyPrice(this.priceData[this.selected_for_price].tomanSelling * this.$S2N(this.tradeData.amount)) * (this.selected_for_price !== 'USDT' ? (this.state.correction_factor[this.selected_for_price][1]) : 1), 0)
                } else if (this.buy) {
                    this.totalPrice = this.$toLocal(this.$prettifyPrice(this.priceData[this.selected_for_price].tomanBuying * this.$S2N(this.tradeData.amount)) * (this.selected_for_price !== 'USDT' ? (this.state.correction_factor[this.selected_for_price][0]) : 1), 0)
                }
            }
        },
        async personalinfo() {
            this.PersonalInformation = await this.$axios.get("/users/init", {
                headers: {
                    'Authorization': `Bearer ${this.$cookies.get('token')}`
                }
            })
            this.credit = this.PersonalInformation.data.data.credits
            this.credit.forEach(element => {
                this.credit[element.code] = element
            })
        },
        //  trade() {
        //   if( this.credit < this.totalPrice) {
        //    this.$emit('creditModal' , true)
        //   }
        //   else {
        //     this.tradeSend()
        //   }

        // },
        async trade() {
            this.state.loading = true
            this.tradeData.amount = this.$S2N(this.tradeData.amount)
            if (this.$cookies.get('userStatus') == ('OTP_DONE' || 'CONFIRMED_IDENTITY' || 'PENDING' || 'CONFLICT' || 'REJECTED')) {
                this.state.authenErr = true
            } else {
                if (this.sell) {
                    this.tradeData.type = 'SELL'
                } else if (this.buy) {
                    this.tradeData.type = 'BUY'
                }
                try {
                    await this.$axios.post(
                        "/orders",
                        this.tradeData, {
                            headers: {
                                Authorization: `Bearer ${this.$cookies.get('token')}`
                            }
                        }
                    )
                    this.state.success = true
                    this.reloadOrder = !this.reloadOrder
                    this.$emit('reloadCredit')
                } catch (err) {
                    const res = err.response || err.toJSON();
                    // console.log(res.data.message);
                    if (res.data.errors) {
                        this.ErrorText.amount = res.data.errors.amount;
                    }
                    // this.ErrorText = res.data.errors.amount;
                    // this.ErrorText = res.data.errors.amount;
                    else if (res.data.status == 400) {
                        this.$emit('creditModal', true)
                    } else {
                        this.generalErr = res.data.message
                    }
                    // console.log('ressssssss',res);

                    // this.$emit('creditModal' , true)
                }
            }

            this.state.loading = false
        },

    },
    async mounted() {
        this.chartData();
        await this.markets();
        this.priceUSDT();
        await this.personalinfo()
        await this.calcPrice()
    },
    created() {
        window.addEventListener("click", (event) => {
            if (!event.target.closest(".coin-drop , .search-coins")) {
                this.coinsDropdown = false;
            }
            if (!event.target.closest(".nets-drop")) {
                this.coinNets = false;
            }
        });
        Pusher

        this.echo = new Echo({
            broadcaster: 'pusher',
            key: 'local',
            wsHost: 'coinrace.website',
            wsPort: 6003,
            wssPort: 6003,
            forceTLS: true,
            cluster: 'mt1',
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        });
        this.echo.channel(`realtime-price`)
            .listen('RealTimePriceEvent', (e) => {
                e.prices.forEach(element => {
                    this.state.price[element.coin] = element
                    this.state.price = e;
                });
                this.priceData = e;
                this.state.reload = !this.state.reload
                this.cryptoList = this.markets_list
                this.calcPrice()
                // console.log('prices',this.state.price)

                // console.log('price in state',this.state.price.prices[1].market)
            });
    },

};
</script>

<style scoped>
.exchange-title {
    font-weight: 500;
    font-size: 20px;
    color: #dbaba7;
}

.exchange-title>p {
    margin-bottom: 0;
}

.exchange {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.head-row {
    position: sticky;
    top: 0;
}

.disable-btn {
    color: black
}

.exchange-inputs {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
    /* width: 390px;
    min-width: 300px;
    margin: auto; */
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.exchange-inputs-title {
    width: 213px;
    height: 40px;
    background: #2f3239;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buy-sell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(21, 22, 26, 0.8);
    border-radius: 5px;
    width: 203px;
    height: 35px;
}

.deActive-sell,
.deActive-buy {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    width: 50%;
    text-align: center;
}

.deActive-sell {
    color: rgba(255, 46, 33, 0.8);
}

.deActive-buy {
    color: rgba(15, 169, 2, 0.8);
}

.active-buy {
    width: 101px;
    height: 27px;
    background: #0fa902;
    border-radius: 5px;
    color: white;
    text-align: center;
    margin: 0 5px;
}

.active-sell {
    width: 101px;
    height: 27px;
    background: #ff2e21;
    border-radius: 5px;
    text-align: center;
    color: white;
    margin: 0 5px;
}

label {
    font-weight: normal;
    /* font-size: 16px; */
    color: white;
    height: 60px;
    font-size: clamp(12px, 1vw, 16px);
}

select,
input {
    width: 100%;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    border: 0;
    height: 40px;
    color: rgba(255, 255, 255, 0.6);
}

.exchange-inputs-container {
    /* width: 390px; */
    height: 520px;
    background: #2f3239;
    background: #2f3239;
}

.buy-sell-form {
    width: 90%;
    margin: 0 auto;
    /* display: grid; */
    row-gap: 8px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 10px 0;
}

.buy-bit-btn {
    width: 100%;
    background: rgb(15, 169, 2);
    border-radius: 5px;
    height: 40px;
    border: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
}

.sell-bit-btn {
    width: 100%;
    background: #ff2e21;
    border-radius: 5px;
    height: 40px;
    border: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
}

.form-text {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin: 0;
    font-size: clamp(14px, 1vw, 16px);
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
}

.form-text-amount {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    direction: ltr;
}

.excahnge-table {
    display: flex;
    column-gap: 30px;
}

.title-p-box {
    color: rgba(255, 176, 7, 1);
    font-weight: 500;
    /* font-size: 16px; */
    font-size: clamp(14px, 1vw, 16px);
}

.title-sub-box {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 156, 7, 0.6);
}

.first-td-crypto {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.box-image {
    width: 37px;
    height: 37px;
}

.crypto-name {
    color: #ffffff;
    font-weight: 500;
    /* font-size: 16px; */
    text-align: right;
}

.crypto-ENname {
    font-weight: 500;
    /* font-size: 16px; */
    color: rgba(255, 255, 255, 0.6);
}

.crypto-name,
.crypto-ENname {
    font-size: clamp(14px, 1vw, 16px);
}

.crypto-prices {
    color: #ffffff;
}

.negative-change {
    color: rgba(255, 46, 33, 1);
    font-weight: 500;
    /* font-size: 18px; */
    font-size: clamp(14px, 1vw, 18px);
    direction: ltr;
}

.positive-change {
    color: rgba(15, 169, 2, 1);
    font-weight: 500;
    /* font-size: 18px; */
}

.table-container {
    width: 100%;
    overflow: scroll;
    margin-top: 40px;
}

.table-container::-webkit-scrollbar {
    display: none;
}

.table-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    height: 520px;
}

.coin-drop {
    cursor: pointer;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    min-width: 800px;
}

tr:nth-child(odd) {
    background: #2f3239;
}

tr:nth-child(even) {
    background: #272a30;
}

.orders {
    margin-bottom: 40px;
}

.btn-dropdown {
    background: transparent;
    border: none;
}

.drop-row-ex:nth-child(even) {
    background-color: #1d1e23;
}

.drop-row-ex:nth-child(odd) {
    background-color: #151619;
}

.drop-row-ex img {
    width: 25px;
    height: 25px;
}

.drop-row-ex {
    display: flex;
    column-gap: 10px;
    width: 200px;
    height: 40px;
    align-items: center;
    border: none;
    padding: 0 10px;
    /* background-color: rgba(255, 255, 255, 0.04); */
}

.drop-name {
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
}

.drop-ENname {
    color: rgba(255, 255, 255, 0.6);
}

.dropdown-title {
    color: #ffffff;
}

.search {
    background: transparent;
}

.drop-row-search {
    background: #2f3239;
    /* border-radius: 5px; */
    display: flex;
    align-items: center;
    border: none;
}

.coin-type-label {
    position: relative;
}

.coin-type-label>img {
    position: absolute;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.search-coins {
    height: 158px;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
    border-radius: 5px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0 10px;
}

.search-coins::-webkit-scrollbar {
    display: none;
}

.myInput-container {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
    padding: 0 10px;
}

#myInput {
    background-image: url("../../../assets/search.png");
    background-position: right;
    background-repeat: no-repeat;
    width: 100%;
    height: 25px;
    font-size: 12px;
    position: sticky;
    top: 0;
    /* padding: 12px 20px 12px 40px; */
    /* border: 1px solid #ddd; */
    /* margin-bottom: 12px; */
    background-color: #2f3239;
    border-radius: 5px;
    color: white;
    padding-right: 20px;
    border: none;
    margin: 5px 0;
}

#myUL {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

#myUL li a {
    border: 1px solid #ddd;
    margin-top: -1px;
    /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
}

#myUL li img {
    width: 25px;
    height: 25px;
}

#myUL li a:hover:not(.header) {
    background-color: #eee;
}

.drop-row {
    display: flex;
    column-gap: 10px;
    width: 250px;
    height: 30px;
    align-items: center;
    border: none;
    margin: 5px 0;
    /* padding: 0 10px; */
    /* margin-right: 20px; */
}

.selected-crypto {
    height: 40px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    column-gap: 5px;
}

.v-dropdown-caller {
    background: rgba(255, 255, 255, 0.04) !important;
    justify-content: flex-end !important;
    height: 40px;
}

@media only screen and (max-width: 1000px) {
    .excahnge-table {
        flex-wrap: wrap;
        row-gap: 20px;
    }
}
</style>
