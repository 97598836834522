<template>
<transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
            <header class="modal-header" id="modalTitle">
                <slot name="header">
                    <button type="button" class="confirm-exit-modal" @click="close" aria-label="Close modal">
                        <img src="@/assets/close-modal.png" alt="">
                    </button>
                    <p class="header">پرداخت بانکی</p>
                </slot>
                <button type="button" class="btn-close" @click="close" aria-label="Close modal">
                </button>
            </header>

            <section class="modal-body" id="modalDescription">
                <div name="body" class="body">
                    <label for="" class="coin-type-label"> انتخاب شماره کارت
                        <div class="selected-crypto">

                            <span v-if="selectedCard != ''">{{selectedCard}}</span>

                        </div>
                        <img class="drop-img coin-drop" src="@/assets/caretdown.png" alt="" @click="showAccounts" />

                        <div v-if="show" class="carts_drop">
                            <div class="drop-row" v-for="(account, index) in accounts" :key="index" @click="selectCard(index)">
                                <span class="drop-name">{{ account.card_number }}</span>

                            </div>
                        </div>
                    </label>
                    <label for="">
                        مبلغ
                        <input type="text" v-model="data2.amount">
                    </label>
                    <label for="">
                        تصویر فاکتور
                        <div class="input-file-container">
                            <label for="">
                                <img v-if="path" class="img" :src="path" alt="">
                                <img v-else class="img" src="@/assets/cloud.png" alt="">
                                <input type="file" @change="upload">
                            </label>
                        </div>
                    </label>
                    <p class="accountInfo">
                        <span class="accountTitle">نام :</span> {{AdminAccount.ACCOUNT_OWNER}}
                    </p>
                    <p class="accountInfo">
                        <span class="accountTitle">شماره کارت :</span> {{AdminAccount.CARD_NUMBER}}
                    </p>
                    <p class="error-p">
                        {{ErrorText}}
                    </p>
                    <button :disabled="data2.amount==null  || data2.account_id==null || data2.image==null" class="withdrawal-btn" @click="sendData">
                        انتقال وجه
                    </button>
                </div>

            </section>

            <footer class="modal-footer">
            </footer>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'DepositTomanCard',
    data() {
        return {
            setting_value: '',
            accounts: [],
            selectedCard: '',
            show: false,
            ErrorText: '',
            image: null,
            data: {
                payment_method: 'TRANSFER',
                amount: null,
                image: null,
                coin_id: 1,
                account_id: null,
            },
            data2: {
                payment_method: 'TRANSFER',
                amount: null,
                image: null,
                coin_id: 1,
                account_id: null,
            },
            AdminAccount: "",
            loadedpicture: '',
            path: '',
        }
    },
    methods: {
        async adminInfo() {
            this.state.loading = true
            const res = await this.$axios.get('/admin-account', {
                headers: {
                    Authorization: `Bearer ${this.$cookies.get('token')}`
                }
            })
            this.AdminAccount = res.data.data
            this.state.loading = false
        },
        close() {
            this.$emit('close');
        },
        showAccounts() {
            this.show = !this.show
        },
        selectCard(index) {
            this.selectedCard = this.accounts[index].card_number
            this.data2.account_id = this.accounts[index].id
            this.show = false
            // alert(this.show)
        },
        async accountInfo() {
            const res = await this.$axios.get(
                "/accounts?status=ACCEPTED", {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.accounts = res.data.data
            console.log(this.info)
        },
        async personalInfo() {
            const res = await this.$axios.get(
                "/settings/otp", {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.setting_value = res.data.data.setting_value
        },
        depositImage(event) {
            this.data.image = event.target.files[0]
            this.data = new FormData()
            this.data.append('image2', event.target.files[0])
            // this.data.image = img
        },
        async sendData() {
            this.state.loading = true
            this.data = new FormData()
            this.data.append('image',this.data2.image) 
            this.data.append('payment_method','TRANSFER')
            this.data.append('amount', this.data2.amount)
            this.data.append('coin_id',1)
            this.data.append('account_id', this.data2.account_id)
            try {
                await this.$axios.post(
                    "/transactions/type/deposit", this.data, {
                        headers: {
                            Authorization: `Bearer ${this.$cookies.get('token')}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                this.state.success = true
                this.$emit('close');
                this.state.loading = false
                this.state.success = true
            } catch (err) {
                const error = err.response || err.toJSON();
                this.ErrorText = error.data.message
                this.state.loading = false
            }
            this.state.loading = false
        },
        upload(event) {
            let file = event.target.files[0]
            let fileReader = new FileReader();
            fileReader.addEventListener("loadstart", (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
            })
            fileReader.addEventListener("progress", (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
            });
            fileReader.addEventListener('load', (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                this.path = event.target.result
                // this.data.image = event.target
            });
            fileReader.readAsDataURL(file)
            this.data2.image = event.target.files[0]
        },

    },
    created() {
        window.addEventListener("click", (event) => {
            if (!event.target.closest(".modal , .toman_withdrawal_btn , .carts_drop , .nav_withdraw , .withdrawal-info-container")) {
                this.$emit('close');
            }

        });
    },
    mounted() {
        this.accountInfo()
        this.personalInfo()
        this.adminInfo()
    }
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    /* shadow */

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    min-height: 407px;
    width: 450px;
}

.modal-header {
    padding: 0 15px;
    display: flex;
    position: relative;
    column-gap: 50px;
    align-items: center;
    justify-content: center;
}

.header {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
}

.modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    /* justify-content: center; */
    text-align: right;
    direction: rtl;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* .modal-header > slot > p {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    font-family: shabnam;
  } */

.modal-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    direction: rtl;
    column-gap: 30px;
}

.modal-body {
    position: relative;
    /* padding: 10px 30px; */
    display: flex;
    flex-direction: row;
    direction: rtl;
    column-gap: 50px;
    flex-wrap: wrap;
    /* row-gap: 20px; */
    justify-content: center;
    width: 90%;
    margin: 20px auto;
}

.body {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
}

input[type="file"] {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.input-file-container {
    height: 100px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;

    label,
    input {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }

    img {
        width: 100%;
        height: 87%;
        transform: translateY(20px);
    }
}

.cancel-exit-modal {
    border-radius: 2px;
    width: 146px;
    height: 40px;
    border: 0;
    font-family: shabnam;
    font-weight: bold;
    font-size: 16px;
}

.confirm-exit-modal {

    box-sizing: border-box;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
}

.cancel-exit-modal {
    background: #00ACC1;
    color: white;
}

.exit-message-modal>p {
    font-weight: 500;
    font-size: 24px;
    font-family: shabnam;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

hr {
    width: 100%;
    background-image: linear-gradient(90deg, black, rgba(255, 255, 255, 0.6), black);
    border: none;
    height: 1px;
    width: 100%;
}

.body-row>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
}

.body-row>div>p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.withdrawal-btn {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #0FA902;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin: 30px 0;
}

input {
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    border: none;
    color: rgba(255, 255, 255, 0.6);
}

label {
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
    margin: 5px 0;
}

.coin-type-label {
    position: relative;
}

.coin-type-label>img {
    position: absolute;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.carts_drop {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    position: absolute;
    background: #656566;
    width: 100%;
    margin-top: 10px;
    z-index: 2;
    border-radius: 5px;
    padding: 5px;
}

.drop-row {
    cursor: pointer;
}

.selected-crypto {
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.04);
    padding: 0 5px;
}

.accountTitle {
    font-size: 18px;
    color: white;
}

.accountInfo {
    font-size: 16px;
    color: #fefefe;
}
</style>
