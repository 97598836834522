<template>
<transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="container">
            <div class="super-spinner">
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'LoadingPage',
    components: {},
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
    background-image: url('../../../assets/vistaLogo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 180px;
    z-index: 200;
}

.modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 470px;
    max-width: 95%;
    position: relative;
    z-index: 200;
}

.modal-body,
.modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

.super-spinner {
    box-sizing: border-box;
    position: relative;
    margin: auto;
    width: 300px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 16px solid transparent;
    border-top-color: #FFD600;
    border-bottom-color: #FFA000;
    transform: rotateZ(-45deg);
    animation: super-spinner 5s linear infinite;
}

.super-spinner::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    inset: -16px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 16px solid transparent;
    border-right-color: #FFD600;
    border-left-color: #FFA000;
    animation: super-spinner 5s linear infinite;
}

@keyframes super-spinner {
    0% {
        transform: rotateZ(0deg);
    }

    25% {
        transform: rotateZ(360deg);
    }

    75% {
        transform: rotateZ(720deg);
    }

    100% {
        transform: rotateZ(1080deg);
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.waiting_text {
    text-align: center;
    margin: 10px 0;
    font-size: 30px;
    color: yellow;
}
</style>
