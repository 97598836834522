<template>
<div class="invite">
    <div>
        <p class="invite-title">
            دعوت دوستان
        </p>
    </div>
    <div class="invite-links">
        <div class="describe1">
            <p>
                شما می‌توانید دوستان خود را با لینک اختصاصی خود به ویستا دعوت کنید. درحال حاضر براساس این طرح، ۰۳% از کارمزد تمامی معاملات کسانی که با لینک شما ثبت نام کرده‌اند به شما پرداخت خواهد شد. همچنین می‌توانید بخشی از این سود را در هنگام ایجاد لینک به فرد دعوت شده اختصاص دهید.
            </p>
        </div>
        <div class="describe2">
            <p>
                <img src="@/assets/dote.png" alt="">
                در صورت استفاده از کد دعوت در مرتبه ی اول ، قیمت مشاهده شده با تخفیف اعمال می گردد
            </p>
            <p>
                <img src="@/assets/dote.png" alt="">
                در صورت استفاده ی مجدد از کد دعوت ، درصد کمیسیون به درجه ی اول باز میگردد.
            </p>
        </div>
        <div class="inputs-container">
            <label for="">
                لینک دعوت
                <img class="copy-Link-img" src="@/assets/copyLink.png" alt="" @click="copyToClipLink">
                <input type="text" :value=referrals.link>
                <div v-if="alertLink" class="alert-copy">
                    لینک کپی شد
                </div>
            </label>
            <label for="">
                کد دعوت
                <img class="copy-Link-img" src="@/assets/copyLink.png" alt="" @click="copyToClipid">
                <input type="text" :value=referrals.code>
                <div v-if="alertId" class="alert-copy">
                    کد کپی شد
                </div>
            </label>
        </div>
    </div>
    <div class="invite-statistics">
        <div class="my-friends">
            <img src="@/assets/invite-friends.png" alt="">
            <p class="my-friends-title">دوستان من</p>
            <p class="my-friends-number">{{$toLocal(statistics.users)}} <span>نفر</span></p>
        </div>
        <div class="incom">
            <img src="@/assets/incom-img.png" alt="">
            <p class="my-friends-title">درآمد من</p>
            <p class="my-friends-number">{{$toLocal(commission)}} <span> تومان</span></p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'InviteFriend',
    components: {

    },
    data() {
        return {
            alertId: false,
            alertLink: false,
            statistics: {},
            Links: [{
                "invitLink": "hgyeiuyieugtet132132646f",
                "invitId": "54564654"
            }],
            referrals: {},
            commission: 0
        }
    },
    methods: {
        copyToClipLink() {
            navigator.clipboard.writeText(this.referrals.link);
            this.alertLink = true
            setTimeout(() => {
                this.alertLink = false;
            }, 1500);
        },
        copyToClipid() {
            navigator.clipboard.writeText(this.referrals.code);
            this.alertId = true
            setTimeout(() => {
                this.alertId = false;
            }, 1500);
            // this.test = true;
            // setTimeout(function() {
            //     this.test = true;
            // },1000)

        },
        async getInfo() {
            this.state.loading = true
            const res = await this.$axios.get(
                "/referrals", {
                    headers: {
                        'Authorization': `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.referrals = res.data.data
            this.state.loading = false
        },
        async getStatistics() {
            this.state.loading = true
            const res = await this.$axios.get(
                "/referring-users/count", {
                    headers: {
                        'Authorization': `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.statistics = res.data.data
            this.state.loading = false
        },
        async getCommition() {
            this.state.loading = true
            const res = await this.$axios.get(
                "/referring-users/commission", {
                    headers: {
                        'Authorization': `Bearer ${this.$cookies.get('token')}`
                    }
                }
            )
            this.commission = res.data.data.commission
            this.state.loading = false
        }
    },
    mounted() {
        this.getInfo()
        this.getStatistics()
        this.getCommition()
    }
}
</script>

<style scoped>
.invite {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.invite-title {
    font-weight: 500;
    font-size: 20px;
    color: #DBABA7;
    margin-bottom: 0;
}

.invite-links,
.invite-statistics {
    border-radius: 5px;
    background: rgba(21, 22, 26, 0.7);
}

.invite-links {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.invite-statistics {
    min-height: 232px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    column-gap: 40px;
    padding: 20px 0;
}

.my-friends-title,
.my-friends-number {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    margin: 5px;
    min-width: 200px;
    ;
}

.my-friends-number {
    color: rgba(255, 255, 255, 0.7);
}

.my-friends-title {
    color: white;
}

.my-friends,
.incom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inputs-container {
    display: flex;
    column-gap: 100px;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: 20px auto 40px auto;
}

label {
    position: relative;
    width: 100%;
    max-width: 336px;
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
    margin: 5px;
}

input {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.7);
    border: none;
    height: 40px;
    width: 100%;
    direction: ltr;
    padding: 0 10px;
}

.copy-Link-img {
    position: absolute;
    right: 10px;
    top: 32px;
    cursor: pointer;
}

.describe1,
.describe2 {
    width: 95%;
    margin: auto;
}

.describe1>p {
    font-weight: normal;
    /* font-size: 16px; */
    font-size: clamp(14px, 1.5vw, 16px);
    color: #FFFFFF;
}

.describe2>p {
    font-weight: normal;
    /* font-size: 16px; */
    font-size: clamp(14px, 1.5vw, 16px);
    color: rgba(255, 255, 255, 0.8);
    margin: 5px;
}

.alert-copy {
    position: absolute;
    font-size: 12px;
    color: white;
    background: rgba(15, 169, 2, 0.2);
    border: 1px solid green;
    border-radius: 5px;
    padding: 5px;
    top: -10px;
    left: 10px;
}

@media only screen and (max-width:1000px) {

    .inputs-container,
    .invite-statistics {
        flex-wrap: wrap;
        row-gap: 20px;
    }

}
</style>
