<template>
<div class="carosel-container">
    <carousel :rtl='true' :per-page="4" :autoplay='true' :loop='true' :speed='900' :per-page-custom='[[320, 1], [1000, 4]]' :autoplay-timeout="3000">
        <slide v-for="(crypto , index) in cryptoList" :key="index" class="main-slide">
            <div class="slide">
                <div class="img-name-price">
                    <div>
                        <img class="image-slide" :src=crypto.coin.logo alt="">
                    </div>
                    <div class="name-price">
                        <div class="coin-name">
                            <p class="name-p">{{crypto.coin.label}}</p>
                            <p class="name-p">{{crypto.coin.code}}</p>
                        </div>
                        <div class="price">
                            <p class="price-p" v-if="price[crypto.coin.code]">
                                {{ $toLocal( $prettifyPrice(price[crypto.coin.code].tomanBuying))}}
                                <span> تومان </span>
                            </p>
                            <p class="price-p" v-else>
                                {{ $toLocal( $prettifyPrice(crypto.buyingPrice))}}
                                <span> تومان </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="change">
                    <p v-if="price[crypto.coin.code]" :class="{
              'negative-change': +price[crypto.coin.code].percentChange < 0,
              'positive-change': +price[crypto.coin.code].percentChange > 0,
            }">
                        % {{ $prettifyPrice(Math.abs(price[crypto.coin.code].percentChange,2)) }}
                        <span v-if="+price[crypto.coin.code].percentChange > 0">+</span>
                        <span v-if="+price[crypto.coin.code].percentChange < 0">-</span>
                    </p>
                </div>
            </div>
        </slide>
    </carousel>
</div>
</template>

<script>
import {
    Carousel,
    Slide
} from 'vue-carousel';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
export default {
    name: 'Slider',
    components: {
        Carousel,
        Slide,

    },
    props: [
        'value'
    ],
    data() {
        return {
            cryptoList: this.value,
            price: [],
        }
    },
    created() {

        Pusher

        this.echo = new Echo({
            broadcaster: 'pusher',
            key: 'local',
            wsHost: 'coinrace.website',
            wsPort: 6003,
            wssPort: 6003,
            forceTLS: true,
            cluster: 'mt1',
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        });
        this.echo.channel(`realtime-price`)
            .listen('RealTimePriceEvent', (e) => {
                e.prices.forEach(element => {
                    this.price[element.coin] = element
                    this.price = e;
                });
                this.price = e;
                this.state.reload = !this.state.reload
            });
    },
}
</script>

<style scoped>
.carosel-container {
    background: linear-gradient(180deg, rgba(39, 42, 51, 0.2), #363941);
}

.main-slide {
    padding: 0 10px;
}

.slide {
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    /* width: 100%; */

    height: 91px;
    display: flex;
    justify-content: space-between;
}

@supports not (backdrop-filter: blur(10px)) {
    .slide {
        background: #252833 !important;
    }
}

.img-name-price {
    display: flex;
    column-gap: 10px;
    align-items: center;
    /* padding: 10px; */
}

.image-slide {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.name-price {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.coin-name {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

.coin-name p,
.price p {
    margin: 0;
}

.name-p {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;

}

.price-p {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);

}

.change {
    padding: 10px;
}

.negative-change,
.positive-change {
    font-weight: 500;
    font-size: 18px;
    margin: 15px 0 0 5px;
}

.negative-change {
    color: rgba(255, 46, 33, 1);
}

.positive-change {
    color: rgba(15, 169, 2, 1);
}
</style><style>
.VueCarousel-inner {
    /* flex-basis: 325px !important; */
}

.VueCarousel {
    direction: revert !important;
    width: 90%;
    /* max-width: 1400px; */
    margin: 0 auto;
}

.VueCarousel-pagination {
    height: 10px !important;
}

.VueCarousel-dot {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: none;
    background-clip: content-box;
    box-sizing: content-box;
    padding: 0;
    border-radius: 100%;
    outline: none;
    background-color: black !important;
    margin-top: 0 !important;
}

.VueCarousel-dot--active {
    background-color: gainsboro !important;
}

.VueCarousel-dot-container {
    margin-top: 10px !important;
    display: none 1 !important;
}
</style>
