<template>
<div class="table-container">
    <perfect-scrollbar>
        <table>
            <tr class="head-row">
                <td v-for="(heade, index) in tr_heade" :key="index" :class='heade.class'>
                    <p class="title-p-box">
                        {{ heade.title }}
                        <span class="title-sub-box" v-if="heade.subTitle">{{heade.subTitle}}</span>
                    </p>
                </td>
            </tr>
            <template v-for="(crypto, index) in markets_list">
                <tr class="row1" :key="index">
                    <template v-if="crypto.coin.code != 'TOMAN'">
                        <td>
                            <div class="first-td-crypto">
                                <img class="box-image" :src="crypto.coin.logo" alt="" />
                                <p class="crypto-name">{{ crypto.coin.label }}</p>
                                <p class="crypto-ENname">{{ crypto.coin.code }}</p>
                            </div>
                        </td>
                        <td class="td-change">
                            <span v-if="priceData[crypto.coin.code]" :class="{'negative-change':priceData[crypto.coin.code].percentChange < 0,'positive-change': priceData[crypto.coin.code].percentChange > 0,}">
                                % {{ $prettifyPrice(Math.abs(priceData[crypto.coin.code].percentChange)) }}
                                <span v-if="priceData[crypto.coin.code].percentChange > 0">+</span>
                                <span v-if="priceData[crypto.coin.code].percentChange < 0">-</span>
                            </span>
                            <!-- <span v-else :class="{'negative-change':crypto.percentChange < 0,'positive-change': priceData[crypto.coin.code].percentChange > 0,}">

                            </span> -->
                        </td>
                        <td class="crypto-prices">
                            <span v-if="priceData[crypto.coin.code]">{{$toLocal($prettifyPrice(priceData[crypto.coin.code].tomanBuying*(crypto.coin.code !=='USDT' ? (state.correction_factor[crypto.coin.code][0]): 1)))}}</span>
                            <span v-else>{{$toLocal($prettifyPrice(crypto.buyingPrice*(crypto.coin.code !=='USDT' ? (state.correction_factor[crypto.coin.code][0]): 1)))}}</span>
                        </td>
                        <td class="crypto-prices">
                            <span v-if="priceData[crypto.coin.code]">{{$toLocal($prettifyPrice(priceData[crypto.coin.code].tomanSelling*(crypto.coin.code !=='USDT' ? (state.correction_factor[crypto.coin.code][1]): 1)))}}</span>
                            <span v-else>{{$toLocal($prettifyPrice(crypto.sellingPrice*(crypto.coin.code !=='USDT' ? (state.correction_factor[crypto.coin.code][0]): 1)))}}</span>
                        </td>
                        <td class="td-diagram">
                            <TrendChart :trend="trend[crypto.coin.code]" :key="crypto.code + change" />
                        </td>
                    </template>
                </tr>
            </template>
        </table>
    </perfect-scrollbar>
</div>
</template>

<script>
import TrendChart from "../../Tools/TrendChart.vue";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
export default {
    name: "CryptoList",
    components: {
        TrendChart,
    },
    data() {
        return {

            priceData: '',
            markets_list: [],
            cryptoList: [],
            coinsDropdown: false,
            buy: true,
            sell: false,
            SellModal: false,
            BuyModal: true,
            coinNets: false,
            amountBuy: "",
            priceBuy: "",
            walletBuy: "",
            netIndex: null,
            selectedNet: "",
            selected: {},
            nets: {},
            chart: {},
            chartOb: {},
            change: 0,
            trend: [],
            totalPrice: null,
            credit: 0,
            generalErr: "",
            selectedOrderId: '',
            ErrorText: {
                amount: ''
            },
            add: true,
            information: {
                minBuy: 0,
                maxBuy: 0,
            },
            tr_heade: [{
                    title: "رمز ارز",
                    class: '',
                },
                {
                    title: "تغییرات",
                    class: 'td-change',
                },
                {
                    title: "قیمت خرید از ما",
                    subTitle: "تومان",
                    class: '',
                },
                {
                    title: "قیمت فروش به ما",
                    subTitle: "تومان",
                    class: '',
                },
                {
                    title: "نمودار",
                    class: 'td-diagram',
                },
            ],
            tradeData: {
                market_id: null,
                type: "",
                amount: 0,
                // address: "$2y$10$XkPl1cihsoWSG3fBi8DNS",
                // network_id: null,
            },
        };
    },
    methods: {
        async markets() {
            const res = await this.$axios.get(
                "/markets?status=ACTIVATED&size=500"
            );
            this.markets_list = res.data.data
            this.cryptoList = this.markets_list
        },
        async priceUSDT() {
            await this.$axios.get(
                "/dollar-prices/last"
            );
            // console.log("USDT", res);
            // this.state.price['USDT'].buying=res.data.data.price
        },
        showCoins() {
            this.coinsDropdown = !this.coinsDropdown;
        },
        async chartData() {
            this.chart = await this.$axios.get(
                "/weekly-prices"
            );

            this.chart.data.data.forEach((element) => {
                this.chartOb[element.coin.code] = element.prices;
            });
            // console.log("aaaaa=>>>", this.chartOb);
            for (let key in this.chartOb) {
                this.trend[key] = Object.values(this.chartOb[key]);
            }
            ++this.change;
            // console.log("trend", this.trend);
            // console.log("ob", this.chartOb);
        },

    },
    async mounted() {
        this.chartData()
        await this.markets()
        this.priceUSDT()

    },
    created() {
        window.addEventListener("click", (event) => {
            if (!event.target.closest(".coin-drop , .search-coins")) {
                this.coinsDropdown = false;
            }
            if (!event.target.closest(".nets-drop")) {
                this.coinNets = false;
            }
        });
        Pusher

        this.echo = new Echo({
           broadcaster: 'pusher',
            key: 'local',
            wsHost: 'coinrace.website',
            wsPort: 6003,
            wssPort: 6003,
            forceTLS: true,
            cluster: 'mt1',
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        });
        this.echo.channel(`realtime-price`)
            .listen('RealTimePriceEvent', (e) => {
                e.prices.forEach(element => {
                    this.state.price[element.coin] = element
                    this.state.price = e;
                });
                this.priceData = e;
                // this.state.reload = !this.state.reload
                // this.cryptoList = this.markets_list
                // console.log('prices',this.state.price)

                // console.log('price in state',this.state.price.prices[1].market)
            });
    },

};
</script>

<style scoped>
.exchange-title {
    font-weight: 500;
    font-size: 20px;
    color: #dbaba7;
}

.exchange-title>p {
    margin-bottom: 0;
}

.exchange {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.head-row {
    position: sticky;
    top: 0;
}

.disable-btn {
    color: black
}

.exchange-inputs {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
    /* width: 390px;
    min-width: 300px;
    margin: auto; */
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.exchange-inputs-title {
    width: 213px;
    height: 40px;
    background: #2f3239;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buy-sell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(21, 22, 26, 0.8);
    border-radius: 5px;
    width: 203px;
    height: 35px;
}

.deActive-sell,
.deActive-buy {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    width: 50%;
    text-align: center;
}

.deActive-sell {
    color: rgba(255, 46, 33, 0.8);
}

.deActive-buy {
    color: rgba(15, 169, 2, 0.8);
}

.active-buy {
    width: 101px;
    height: 27px;
    background: #0fa902;
    border-radius: 5px;
    color: white;
    text-align: center;
    margin: 0 5px;
}

.active-sell {
    width: 101px;
    height: 27px;
    background: #ff2e21;
    border-radius: 5px;
    text-align: center;
    color: white;
    margin: 0 5px;
}

label {
    font-weight: normal;
    /* font-size: 16px; */
    color: white;
    height: 60px;
    font-size: clamp(12px, 1vw, 16px);
}

select,
input {
    width: 100%;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    border: 0;
    height: 40px;
    color: rgba(255, 255, 255, 0.6);
}

.exchange-inputs-container {
    /* width: 390px; */
    height: 520px;
    background: #2f3239;
    background: #2f3239;
}

.buy-sell-form {
    width: 90%;
    margin: 0 auto;
    /* display: grid; */
    row-gap: 8px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 10px 0;
}

.buy-bit-btn {
    width: 100%;
    background: rgb(15, 169, 2);
    border-radius: 5px;
    height: 40px;
    border: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
}

.buy-bit-btn:disabled {
    color: gray;
    background: rgba(15, 169, 2, 0.5);
}

.sell-bit-btn {
    width: 100%;
    background: #ff2e21;
    border-radius: 5px;
    height: 40px;
    border: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
}

.form-text {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin: 0;
    font-size: clamp(14px, 1vw, 16px);
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
}

.form-text-amount {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    direction: ltr;
}

.excahnge-table {
    display: flex;
    column-gap: 30px;
}

.title-p-box {
    color: rgba(255, 176, 7, 1);
    font-weight: 500;
    /* font-size: 16px; */
    font-size: clamp(14px, 1vw, 16px);
}

.title-sub-box {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 156, 7, 0.6);
}

.first-td-crypto {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.box-image {
    width: 37px;
    height: 37px;
}

.crypto-name {
    color: #ffffff;
    font-weight: 500;
    /* font-size: 16px; */
    text-align: right;
}

.crypto-ENname {
    font-weight: 500;
    /* font-size: 16px; */
    color: rgba(255, 255, 255, 0.6);
}

.crypto-name,
.crypto-ENname {
    font-size: clamp(12px, 1vw, 16px);
}

.crypto-prices {
    color: #ffffff;
    font-size: clamp(12px, 1vw, 16px);
}

.negative-change {
    color: rgba(255, 46, 33, 1);
    font-weight: 500;
    /* font-size: 18px; */
    font-size: clamp(12px, 1vw, 18px);
    direction: ltr;
}

.positive-change {
    color: rgba(15, 169, 2, 1);
    font-weight: 500;
    font-size: clamp(12px, 1vw, 18px);
    /* font-size: 18px; */
}

.table-container {
    width: 100%;
    overflow: scroll;
    margin-top: 40px;
}

.table-container::-webkit-scrollbar {
    display: none;
}

.table-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    height: 520px;
}

.coin-drop {
    cursor: pointer;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    /* min-width: 800px; */
}

tr:nth-child(odd) {
    background: #2f3239;
}

tr:nth-child(even) {
    background: #272a30;
}

.orders {
    margin-bottom: 40px;
}

.btn-dropdown {
    background: transparent;
    border: none;
}

.drop-row-ex:nth-child(even) {
    background-color: #1d1e23;
}

.drop-row-ex:nth-child(odd) {
    background-color: #151619;
}

.drop-row-ex img {
    width: 25px;
    height: 25px;
}

.drop-row-ex {
    display: flex;
    column-gap: 10px;
    width: 200px;
    height: 40px;
    align-items: center;
    border: none;
    padding: 0 10px;
    /* background-color: rgba(255, 255, 255, 0.04); */
}

.drop-name {
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
}

.drop-ENname {
    color: rgba(255, 255, 255, 0.6);
}

.dropdown-title {
    color: #ffffff;
}

.search {
    background: transparent;
}

.drop-row-search {
    background: #2f3239;
    /* border-radius: 5px; */
    display: flex;
    align-items: center;
    border: none;
}

.coin-type-label {
    position: relative;
}

.coin-type-label>img {
    position: absolute;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.search-coins {
    height: 158px;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
    border-radius: 5px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0 10px;
}

.search-coins::-webkit-scrollbar {
    display: none;
}

.myInput-container {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
    padding: 0 10px;
}

#myInput {
    background-image: url("../../../assets/search.png");
    background-position: right;
    background-repeat: no-repeat;
    width: 100%;
    height: 25px;
    font-size: 12px;
    position: sticky;
    top: 0;
    /* padding: 12px 20px 12px 40px; */
    /* border: 1px solid #ddd; */
    /* margin-bottom: 12px; */
    background-color: #2f3239;
    border-radius: 5px;
    color: white;
    padding-right: 20px;
    border: none;
    margin: 5px 0;
}

#myUL {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

#myUL li a {
    border: 1px solid #ddd;
    margin-top: -1px;
    /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
}

#myUL li img {
    width: 25px;
    height: 25px;
}

#myUL li a:hover:not(.header) {
    background-color: #eee;
}

.drop-row {
    display: flex;
    column-gap: 10px;
    width: 250px;
    height: 30px;
    align-items: center;
    border: none;
    margin: 5px 0;
    /* padding: 0 10px; */
    /* margin-right: 20px; */
}

.selected-crypto {
    height: 40px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    column-gap: 5px;
}

.v-dropdown-caller {
    background: rgba(255, 255, 255, 0.04) !important;
    justify-content: flex-end !important;
    height: 40px;
}

@media only screen and (max-width: 1000px) {
    .excahnge-table {
        flex-wrap: wrap;
        row-gap: 20px;
    }
}

@media only screen and (max-width: 850px) {

    .td-diagram,
    .td-change,
    .box-image,
    .crypto-ENname {
        display: none;

    }
}
</style>
