<template>
<div class="background">
    <NavbarHome class="log-nav" />
    <div class="main">
        <div class="form-container">
            <div class="form">
                <LoginForm @sendToParent="sendToOtp" v-if="$route.path=='/login'" />
                <SubscribeForm v-if="$route.path=='/subscribe'" />
                <ForgotEmail v-if="$route.path=='/forgot-email'" />
                <ChangePass v-if="$route.path=='/change-pass'" />
                <EmailOTP v-if="$route.path=='/email-otp'" />
                <OTPLogin :toOtpLogin="loginInfo" v-if="$route.path=='/login-otp'" />
                <FinoVerifying v-if="$route.path=='/fino-verifying'" />
                <RegisterEmail v-if="$route.path=='/register-email'" />
                <ForgetConfirm v-if="$route.path=='/forget-confirm'" />
                <Finnotech v-if="$route.path=='/finnotech'" />
            </div>
        </div>
        <div class="company">
            <div class="company-container">

                <img class="company-img" src="@/assets/vistaLogo.svg" alt="">
                <p class="company-text">
                    لطفا پیش از وارد کردن هرگونه اطلاعات،آدرس مرورگر وب خود را با آدرس فوق مقایسه نموده و در صورت مشاهده هرگونه مغایرت از ادامه ی کار خودداری فرمایید.
                    <br>
                    تحت هیچ شرایطی اطلاعات حساب کاربری)نام کاربری و رمزعبور( خود را در اختیار دیگران قرار ندهید. در غیر این صورت مسئولیت تمام اتفاقات احتمالی بر عهده ی شخص شما خواهد بود.
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import SubscribeForm from './SubscribeForm.vue'
import NavbarHome from '../HomePage/NavbarHome.vue'
import LoginForm from "./LoginForm"
import ForgotEmail from './forgotEmail.vue'
import ChangePass from './ChangePass.vue'
import EmailOTP from "./EmailOTP.vue"
import OTPLogin from './OTPLogin.vue'
import FinoVerifying from '../Profile/Authorization/FinoVerifying.vue'
import RegisterEmail from './RegisterEmail.vue'
import ForgetConfirm from './ForgetConfirm.vue'
import Finnotech from './Finnotech.vue'
export default {
    name: 'HomePage',
    components: {
        NavbarHome,
        LoginForm,
        SubscribeForm,
        ForgotEmail,
        ChangePass,
        EmailOTP,
        OTPLogin,
        FinoVerifying,
        RegisterEmail,
        ForgetConfirm,
        Finnotech,

    },
    data() {
        return {
            loginInfo: ''
        }
    },
    methods: {
        sendToOtp(value) {
            this.loginInfo = value
        }
    }

}
</script>

<style scoped>
.body {
    background: red;
}

.background {
    /* background-color: #2F3239; */
    /* background-image: url('../../assets/log-back.png'); */
    background-image: url('../../assets/baner.png');
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: screen;
}

/* .background::before {
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    position: absolute;
    opacity: 0.01;
} */

.log-nav {
    background: rgba(21, 22, 25, 0.35) !important;
}

.main {
    max-width: 854px;
    margin: auto;
    margin-top: 40px;
    /* background: #191C25; */
    column-gap: 20px;
    background: rgba(21, 22, 26, 0.9);
}

.form-container,
.company {

    min-height: 600px;
    /* background: #191C25; */
}

.form-container {
    width: 53%;
    flex-grow: 1;
    max-width: 600px;
    margin: auto;

}

.company {
    width: 47%;
    padding-top: 20px;
    flex-grow: 1;
    max-width: 600px;
    margin: auto;
    background: rgba(21, 22, 25, 0.35);
    /* bg blur */

    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(20px);
}

.main {
    display: flex;
    width: 90%;
}

.company-container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.company-img {
    max-width: 100%;
    width: 275px;
    height: 275px;
}

.company-text {
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    /* margin: 10px; */
    opacity: 0.8;
}

.form {
    width: 90%;
    margin: auto;
    margin-top: 20px;

}

@media only screen and (max-width:1000px) {
    .main {
        flex-wrap: wrap;
    }
}
</style>
