<template>
<transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal" :key="selectedCoin[0]">
            <!-- <img @click="closeModale" class="close-icon" src="@/assets/close-icon.svg" alt=""> -->
            <span class="message-title">
            </span>
            <div class="exchange-inputs-container">
                <div class="buy-sell-form">
                    <label for="" class="coin-type-label">نوع ارز 
                        <div class="selected-crypto">
                            <span v-if="selectedCoin!=''">{{selectedCoin[selectedWallet.id].coin.label}}</span>
                        </div>

                    </label>
                    <label for="" class="coin-type-label"> شبکه
                        <div class="selected-crypto">
                            <span v-if="selectedNets != ''">{{ selectedNets }}</span>
                            <span v-else-if="nets!=''">{{nets[0].name}}</span>
                        </div>
                        <img class="drop-img coin-drop" src="@/assets/caretdown.png" alt="" @click="showCoins" />

                        <div class="search-coins" v-if="coinsDropdown">
                            <div class="drop-row" v-for="(crypto, index) in nets" :key="index" @click="select(index)">
                                <span class="drop-name" @click="seletctNet(index)">{{ crypto.name }}</span>
                            </div>
                        </div>
                        <p class="error-p">
                            {{crypto_network_idErr}}
                        </p>
                    </label>
                    <p class="error-p">
                        {{addressErr}}
                    </p>
                    <label for="">
                        مقدار مورد نظر
                        <input type="text"  @input="tradeData.amount = $toLocal(tradeData.amount)" v-model="tradeData.amount"/>
                        <p class="error-p">
                            <!-- {{errorText.errors.amount}} -->
                        </p>
                    </label>
                    <p class="error-p">
                        {{amountErr}}
                    </p>
                </div>
            </div>
            <p class="error-p">
                {{errorText}}
            </p>
            <div class="btns">
                <button class="deposit-btn" @click="depositReq" :disabled="!$S2N(tradeData.amount)" v-if="selectedCoin != ''">
                    واریز {{selectedCoin[selectedWallet.id].coin.label}}
                </button>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'DepositModal',
    components: {

    },
    props: [
        'value', 'selectedCoinWallet'
    ],
    data() {
        return {
            close: false,
            test: 1,
            cryptoList: '',
            coinsDropdown: false,
            priceData: this.state.price,
            selected: {},
            totalPrice: 1,
            selectedNets: "",
            depositData: {},
            selectedWallet: "",
            errorText: '',
            transactionId: null,
            addressErr: "",
            amountErr: "",
            crypto_network_idErr: "",
            tradeData: {
                market_id: null,
                type: "",
                amount: 0,
            },
            nets: [],
            netId: '',
            information: [{
                wage: 321321321,
                minBuy: 0.7725,
                maxBuy: 5,
            }, ],
            selectedId: '3',
            selectedCoin: {},
        }
    },
    methods: {
        closeModale() {
            this.$emit('closeModal', this.close)
        },
        async select(index) {
            this.selected = this.cryptoList[index];
            this.selected_for_price = this.selectedCoin[this.selectedWallet.id].coin.code + "USDT";
            this.coinsDropdown = false;
            this.tradeData.market_id = this.cryptoList[index].id
        },
        showCoins() {
            this.coinsDropdown = !this.coinsDropdown;
        },
        async getNets() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/' + this.selectedWallet.id + '/networks?status=ACTIVATED&deposit_status=ACTIVATED', {
                headers: {
                    'Authorization': `Bearer ${this.$cookies.get('token')}`
                }
            })

            this.nets = res.data.data
            this.netId = res.data.data[0] ? this.netId = res.data.data[0].id : ''

            this.state.loading = false
        },
        seletctNet(index) {
            this.selectedNets = this.nets[index].name
            this.netId = this.nets[index].id
            this.$emit('setNetId', this.netId)
            this.coinsDropdown = false
        },
        async depositReq() {
            this.state.loading = true
            this.depositData.payment_method = 'CRYPTO'
            this.depositData.amount = this.$S2N(this.tradeData.amount)
            this.depositData.coin_id = this.selectedWallet.id
            this.depositData.crypto_network_id = this.netId
            try {
                const res = await this.$axios.post(
                    '/transactions/type/deposit',
                    this.depositData, {
                        headers: {
                            'Authorization': `Bearer ${this.$cookies.get('token')}`
                        }
                    }
                )
                this.transactionId = res.data.data.id
                this.$emit('setNetId', this.netId)
                this.$emit('closeModal', this.transactionId)
            } catch (err) {
                const error = err.response || err.toJSON();
                this.errorText = error.data.message
                if (error.data.errors && error.data.errors.crypto_network_id) {
                    this.addressErr = error.data.errors.crypto_network_id[0]
                }
                if (error.data.errors && error.data.errors.amount) {
                    this.amountErr = error.data.errors.amount[0]
                }
                if (error.data.message.includes('احراز')) {
                    this.state.authenErr = true
                }
                if (error.data.message.includes('موجودی')) {
                    this.$emit('openCredit')
                } 
            }
            this.state.loading = false
        },
        async getCrypto() {
            const res = await this.$axios.get(
                "/markets?size=500"
            );
            this.cryptoList = res.data.data;
            this.state.loading = false;
        },
    },
    created() {
        window.addEventListener("click", (event) => {
            if (!event.target.closest(".coin-drop , .search-coins")) {
                this.coinsDropdown = false;
            }
            if (!event.target.closest(".nets-drop")) {
                this.coinNets = false;
            }
            if (!event.target.closest('.modal , .deposit_btn , .withdrawal_btn , .search-coins')) {
                this.$emit('closeModal', this.close)
            } else {
                return true
            }
        });
    },
    async mounted() {
        this.state.loading = true
        await this.getCrypto()
        this.cryptoList.forEach(element => {
            this.selectedCoin[element.coin_id] = element

        })
        this.selectedWallet = this.selectedCoinWallet
        this.getNets()
        this.netId = this.nets[0].id
        this.selected_for_price = (this.selectedCoin[this.selectedWallet.id].coin.code !== 'USDT' ? this.selectedCoin[this.selectedWallet.id].coin.code + "USDT" : 'USDTTOMAN');
        ++this.test
        this.state.loading = false
    }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 400px;
    min-height: 200px;
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
}

.close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.message-title {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
}

.inputs-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
    column-gap: 16px;
    margin-top: 35px;
}

.deposit-btn {
    height: 40px;
    width: 100%;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    background: transparent;
    border: none;
    color: white;
    background: #0FA902;
}

.btns {
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    width: 100%;
    align-items: center;
}

.coin-type-label {
    position: relative;
}

.coin-type-label>img {
    position: absolute;
    top: 30px;
    left: 10px;
    cursor: pointer;
}

.selected-crypto {
    height: 40px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    column-gap: 5px;
}

.form-text {
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin: 0;
    font-size: clamp(14px, 1vw, 16px);
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
}

.form-text-amount {
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    direction: ltr;
}

.buy-sell-form {
    // width: 90%;
    // margin: 0 auto;
    /* display: grid; */
    row-gap: 8px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 10px 0;
}

.exchange-inputs-container {
    width: 100%;
    // height: 520px;
    // background: #2f3239;
    // background: #2f3239;
}

.search-coins {
    min-height: 10px;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #3A3D44;
    border-radius: 5px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 5px 5px;
}

.search-coins::-webkit-scrollbar {
    display: none;
}

.myInput-container {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
    padding: 0 10px;
}

#myUL {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

#myUL li a {
    border: 1px solid #ddd;
    margin-top: -1px;
    /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
}

#myUL li img {
    width: 25px;
    height: 25px;
}

#myUL li a:hover:not(.header) {
    background-color: #eee;
}

.drop-row {
    display: flex;
    column-gap: 10px;
    width: 250px;
    height: 30px;
    align-items: center;
    border: none;
    /* padding: 0 10px; */
    /* margin-right: 20px; */
}

.drop-row-search {
    background: #2f3239;
    /* border-radius: 5px; */
    display: flex;
    align-items: center;
    border: none;
}

.drop-row-ex:nth-child(even) {
    background-color: #1d1e23;
}

.drop-row-ex:nth-child(odd) {
    background-color: #151619;
}

.drop-row-ex img {
    width: 25px;
    height: 25px;
}

.drop-row-ex {
    display: flex;
    column-gap: 10px;
    width: 200px;
    height: 40px;
    align-items: center;
    border: none;
    padding: 0 10px;
    /* background-color: rgba(255, 255, 255, 0.04); */
}

#myInput {
    background-image: url("../../../assets/search.png");
    background-position: right;
    background-repeat: no-repeat;
    width: 100%;
    height: 25px;
    font-size: 12px;
    position: sticky;
    top: 0;
    background-color: #2f3239;
    border-radius: 5px;
    color: white;
    padding-right: 20px;
    border: none;
    margin: 5px 0;
}

.selected-img {
    width: 25px;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}
</style>
