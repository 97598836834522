<template>
<div class="application">
    <div class="introduce">
        <p class="title">
            اپلیکیشن صرافی
            <span class="name">ویستا</span>
        </p>
        <p class="introduce-p">
            خرید و فروش و معامله‌ی انواع ارزهای دیجیتال در هر زمان و هر مکان
        </p>
        <div class="links">
            <a class="link" :href="androidLink" target="_blank">
                <div>
                    <img src="@/assets/android.svg" alt="">
                </div>
                <div>
                    <p class="for">
                        دریافت مستقیم برای
                    </p>
                    <p class="platform">
                        اندروید
                    </p>
                </div>
            </a>
            <a class="link" :href="iosLink" target="_blank">
                <div>
                    <img class='links-icon' src="@/assets/apple.svg" alt="">
                </div>
                <div>
                    <p class="for">
                        دریافت مستقیم برای
                    </p>
                    <p class="platform">
                        IOS
                    </p>
                </div>
            </a>
        </div>
    </div>
    <img class="mob-img" src="@/assets/mob-img.png" alt="">
</div>
</template>

<script>
export default {
    name: 'Aplication',
    components: {

    },
    data() {
        return {
            info: {},
            androidLink: '',
            iosLink: ''
        }
    },
    methods: {
        async getInfo() {
            const res = await this.$axios('options/app')
            let links = res.data.data
            this.androidLink = links.find(a => a.option_key == 'android').option_value
            this.iosLink = links.find(a => a.option_key == 'ios').option_value

        },
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.application {
    display: flex;
    align-items: center;
    flex-grow: 1;
    column-gap: 200px;
    padding: 0 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;

    .introduce {

        .title,
        .name {
            font-weight: normal;
            font-size: 32px;
        }

        .title {
            color: white;
        }

        .name {
            color: rgba(255, 176, 7, 1);
        }
    }

    .introduce-p {
        color: rgba(255, 255, 255, 0.6);
        font-weight: normal;
        font-size: 22px;
        text-align: center;
    }

    .links {
        display: flex;
        row-gap: 10px;
        column-gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        .link {
            background: #151515;
            border-radius: 3px;
            width: 200px;
            height: 70px;
            display: flex;
            column-gap: 20px;
            align-items: center;
            padding: 0 10px;
            box-sizing: border-box;
            cursor: pointer;

            .for {
                font-weight: normal;
                font-size: 12px;
                color: #FFFFFF;
                margin: 0;
            }

            .platform {
                font-weight: bold;
                font-size: 16px;
                color: #FFFFFF;
                margin: 0;
            }
        }
    }

    .mob-img {
        max-width: 570px;
        height: 450px;
        max-width: 100%;
    }
}

.links-icon {
    width: 40px;
}

@media only screen and (max-width:600px) {
    .links {
        justify-content: center;
    }
}
</style>
