<template>
    <div>
        <p class="tickets-title">آخرین تیکت ها</p>
        <table>
            <tr class="tr-head">
                <td>
                    عنوان
                </td>
                <td>
                    وضعیت 
                </td>
                <td>
                    زمان
                </td>
            </tr>
            <tbody v-if="ticketsList != ''">
            <tr v-for="(Tickets , index) in ticketsList" :key="index" class="tr-body">
                <td>
                    {{Tickets.title}}
                </td>
                <td>
                    <p v-if="Tickets.status=='DONE'" class="response">پاسخ داده شده</p>
                    <p v-if="Tickets.status=='PENDING'">مشاهده نشده</p>
                </td>
                <td>
                    {{$G2J(Tickets.created_at)}}
                </td>
            </tr>
        </tbody>
        <tr class="no-content" v-else>
            <td colspan="3">
                تیکتی ثبت نشده است
            </td>
        </tr>
        </table>
    </div>
</template>

<script>
export default {
  name: 'Tickets',
  components :{

    
  },
  data() {
      return {
          ticketsList:{}
      }
  },
  methods : {
      async ticket() {
          const res = await this.$axios.get('/tickets' ,
          {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
          this.ticketsList = res.data.data
      }
  },
  mounted() {
      this.ticket()
  }
  }
</script>

<style scoped>

    .tickets-title {
        font-weight: 500;
font-size: 18px;
color: #DBABA7;
    }
    table {
        width: 100%;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
border-radius: 5px;
text-align: center;
    }
    .tr-head {
        font-weight: 500;
font-size: 16px;
color: rgba(255, 156, 7, 1);
    }
    .tr-body {
        font-weight: normal;
/* font-size: 16px; */
font-size: clamp(10px , 1.5vw ,16px);
color: #FFFFFF;
    }
    tr{
        height: 40px;
        width: 100%;
        display: table;
        table-layout: fixed;
    }
    tr p {
        margin: 0;
    }
    tr:nth-child(odd) {
        background: #2F3239;
    }
    tr:nth-child(even) {
        background: #272A30;
    }
    .response {
        color: #0FA902;
    }
   tbody {
       height: 280px;
       overflow: auto;
       display: block;
       -ms-overflow-style: none;
       scrollbar-width: none;
   }
   tbody::-webkit-scrollbar {
       display: none;
   }
   .no-content {
       color: red;
       opacity: 0.7;
   }
</style>
