<template>
<div class="history">
    <div class="orders-history">
        <div class="orders-history-title">
            تاریخچه سفارشات
        </div>
        <div class="drop-down-container">
            <div class="dropdown">
                <div class="dropdown_title" @click="typesItems = !typesItems">
                    <span v-if="selectedType != ''">
                        {{selectedType}}
                    </span>
                    <span v-else>
                        نوع سفارش 
                    </span>
                    <img class="open_drop" src="@/assets/caretdown.png" alt="" >
                </div>
                <transition name="fade">
                    <div class="dropdown_list" v-if="typesItems">
                        <div v-for="(type , index) in types" :key="index" @click="selectType(index)">
                            {{type.title}}
                        </div>
                    </div>
                </transition>
            </div>
            <!-- <div class="dropdown">
                <div class="dropdown_title" @click="showOrdersDate = !showOrdersDate">
                    <span v-if="orderDateSelect != ''">
                        {{orderDateSelect}}
                    </span>
                    <span v-else>
                        بازه زمانی
                    </span>
                    <img class="open_drop" src="@/assets/caretdown.png" alt="" >
                </div>
                <transition name="fade">
                    <div class="dropdown_list" v-if="showOrdersDate">
                        <div v-for="(type , index) in ordersDate" :key="index" @click="selectDateOrder(index)">
                            {{type.title}}
                        </div>
                    </div>
                </transition>
            </div> -->
        </div>

        <div class="table-container-orders">
            <perfect-scrollbar @ps-y-reach-end="getOrders" ref="scroll">
                <table class="orders">
                    <tr class="tr-head-orders">
                        <td>
                            شماره سفارش
                        </td>
                        <td>
                            نوع سفارش
                        </td>
                        <td>
                            رمز ارز
                        </td>
                        <td>
                            مقدار
                        </td>
                        <td>
                            قیمت واحد <span class="tooman-unit"> تومان </span>
                        </td>
                        <td>
                            قیمت کل <span class="tooman-unit"> تومان </span>
                        </td>
                        <td>
                            زمان
                        </td>
                        <td>
                            وضعیت
                        </td>
                    </tr>
                    <tbody>
                        <template v-for="(OrdersHistory , index) in orders">
                            <tr :key="index" class="tr-body-orders" v-if="OrdersHistory != 'PENDING'">
                                <td>
                                    {{OrdersHistory.id}}
                                </td>
                                <td :class="{'td-buy' : OrdersHistory.type==='BUY' , 'td-sell':OrdersHistory.type==='SELL'}">
                                    <span v-if="OrdersHistory.type==='BUY'">
                                        خرید
                                    </span>
                                    <span v-if="OrdersHistory.type==='SELL'">
                                        فروش
                                    </span>
                                </td>
                                <td>
                                    {{OrdersHistory.market.coin.label}}
                                </td>
                                <td>
                                    {{OrdersHistory.amount}}
                                </td>
                                <td>
                                    {{$toLocal($prettifyPrice(OrdersHistory.price))}}
                                </td>
                                <td>
                                    {{OrdersHistory.total.toLocaleString()}}
                                </td>
                                <td>
                                    {{$G2J(OrdersHistory.created_at)}}
                                </td>
                                <td>
                                    <img v-if="OrdersHistory.status=='ACCEPTED'" src="@/assets/status-done.png" alt="">
                                    <img v-else src="@/assets/status-fail.png" alt="">
                                </td>
                            </tr>

                            
                        </template>
                    </tbody>
                </table>
            </perfect-scrollbar>
        </div>

    </div>

</div>
<!-- </div> -->
</template>

<script>
export default {
    name: 'OrdersHistory',
    components: {},
    data() {
        return {
            text: '',
            status: '',
            historyCheck: false,
            orders: [],
            exchange: [],
            cryptoList: '',
            coinsDropdown: false,
            typesItems: false,
            selectedType: "",
            checkMessage: "",
            types: [{
                    title: "همه",
                    filter: ""
                },
                {
                    title: "خرید",
                    filter: "BUY"
                },
                {
                    title: "فروش",
                    filter: "SELL"
                }
            ],
            showOrdersDate: false,
            orderDateSelect: "",
            ordersDate: [{
                    title: "همه",
                    filter: ""
                },
                {
                    title: "روزانه",
                    filter: ""
                },
                {
                    title: "هفتگی",
                    filter: ""
                }
            ],
            transactionItems: false,
            selectedtransaction: "",
            transaction: [{
                    title: "همه",
                    filter: ""
                },
                {
                    title: "واریز",
                    filter: "DEPOSIT"
                },
                {
                    title: "برداشت",
                    filter: "WITHDRAW"
                }
            ],
            showTransactionDate: false,
            selectTransactionDate: "",
            transactionDate: [{
                    title: "همه",
                    filter: ""
                },
                {
                    title: "روزانه",
                    filter: ""
                },
                {
                    title: "هفتگی",
                    filter: ""
                }
            ],
            times: [{
                    title: 'همه'
                },
                {
                    title: 'روزانه'
                },
                {
                    title: 'هفتگی'
                },
                {
                    title: 'ماهانه'
                }
            ],
            filter: {
                type: '',
                created_atFrom: '',
                created_atTo: ''
            },
            lastPage: 1,
            page: 0,
            ordersFiltered:false,
            data: {
                id: "",
                netId: ""
            }
        }
    },
    methods: {
        myFunction() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("myInput");
            filter = input.value;
            ul = document.getElementById("myUL");
            li = ul.getElementsByTagName("li");
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByClassName("drop-name")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.indexOf(filter) > -1) {
                    li[i].style.display = "";
                } else {
                    li[i].style.display = "none";
                }
            }

        },
        async getOrders() {
            if (this.page < this.lastPage && !this.ordersFiltered) {
                this.state.loading = true
                this.page++
                const res = await this.$axios.get(
                    '/orders', {
                        headers: {
                            'Authorization': `Bearer ${this.$cookies.get('token')}`
                        },
                        params: {
                            size: 10,
                            page: this.page,
                        }

                    }
                )
                if (res) {
                    this.lastPage = res.data.meta.last_page
                    this.orders = [...this.orders, ...res.data.data]
                }
                this.state.loading = false
            }

        },
        showCoins() {
            this.coinsDropdown = !this.coinsDropdown
        },
        async selectType(index) {
            this.state.loading = true
            this.selectedType = this.types[index].title
            // this.selectedType_filter = this.types[index].filter
            let param = {}
            if (this.types[index].filter != '') {
                param.type = this.types[index].filter
                this.ordersFiltered = true
            }
            const res = await this.$axios.get(
                '/orders', {
                    headers: {
                        'Authorization': `Bearer ${this.$cookies.get('token')}`
                    },
                    params: param
                }
            )
            this.orders = res.data.data
            this.state.loading = false
        },
        async selectDateOrder(index) {
            this.state.loading = true
            this.showOrdersDate = false
            this.orderDateSelect = this.ordersDate[index].title
            const res = await this.$axios.get(
                '/transactions', {
                    headers: {
                        'Authorization': `Bearer ${this.$cookies.get('token')}`
                    },
                    params: {
                        type: this.ordersDate[index].filter
                    }
                }
            )
            this.exchange = res.data.data
            this.state.loading = false
        },

    },
    created() {
        window.addEventListener("click", (event) => {
            if (!event.target.closest(".dropdown_title , .dropdown_title")) {
                this.typesItems = false;
                this.showOrdersDate = false;
                this.transactionItems = false
                this.showTransactionDate = false
            }
        });
    },
    mounted() {
        this.getOrders()

    }
}
</script>

<style scoped>
.complete_btn {
    border: 1px solid #FF9C07;
    border-radius: 5px;
    width: 50px;
    color: #FF9C07;
    background: transparent;
}

.history {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.history-title {
    font-weight: 500;
    font-size: 20px;
    color: #DBABA7;
}

.dropdown {
    background: rgba(21, 22, 26, 0.3);
    border-radius: 5px;
    width: 150px;
    height: 35px;
    font-size: 16px;
    color: #FFFFFF;
    position: relative;
    z-index: 2;
}

.dropdown_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
}

.dropdown_list {
    position: absolute;
    height: 90px;
    width: 100%;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
    background: rgba(21, 22, 26, 1);
    border-radius: 5px;
    margin-top: 5px;
}

.dropdown_list>div {
    cursor: pointer;
    padding: 0 5px;
    height: 30px;
}

.dropdown_list>div:nth-child(odd) {
    background: rgba(21, 22, 26, 1);

}

.dropdown_list>div:nth-child(even) {
    background: rgba(47, 50, 57, 0.3);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.orders-history-title {
    font-weight: 500;
    font-size: 18px;
    color: #DBABA7;
}

.table-container-orders,
.table-container-exchange {
    overflow: scroll;
    height: 400px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.table-container-orders::-webkit-scrollbar,
.table-container-exchange::-webkit-scrollbar {
    display: none;
}

.orders,
.exchange {
    width: 100%;
    text-align: center;
    border-radius: 5px;
    height: auto;
    border-collapse: collapse;
    min-width: 700px;

}

tr:nth-child(odd) {
    background: #2F3239;
}

tr:nth-child(even) {
    background: #272A30;
}

.td-buy {

    color: #0FA902;

}

.td-sell {
    color: #FF2E21;
}

.tr-body-orders {
    height: 40px;
    font-weight: normal;
    /* font-size: 16px; */
    color: #FFFFFF;
    font-size: clamp(12px, 1vw, 16px);
}

.tr-head-orders {
    font-size: clamp(12px, 1vw, 16px);
    height: 60px;
    font-style: normal;
    font-weight: 500;
    color: rgba(255, 176, 7, 1);
    position: sticky;
    top: 0;
}

.drop-down-title {
    width: 150px;
    height: 35px;
    border-radius: 5px;
    /* background-color:rgba(21, 22, 26, 0.3); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.drop-img {
    width: 24px;
    height: 24px;
}

.drop-down-title>img {
    margin-left: 10px;
    width: 2vw;
}

.drop-down-title>p {
    margin-right: 10px;
}

.drop-down-title>p {
    font-weight: normal;
    /* font-size: 16px; */
    font-size: clamp(10px, 1.5vw, 16px);
    color: #FFFFFF;
}

.drop-down-container {
    display: flex;
    /* column-gap: 30px; */
    column-gap: 3vw;
}

.orders-history,
.exchange-history {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.exchange-history {
    margin-bottom: 40px;
}

.tooman {
    font-weight: normal;
    font-size: 14px;
    color: white;
}

.tooman-unit {
    font-size: 14px;
    color: rgba(255, 176, 7, 1);
    opacity: 0.6;
}

select {
    background: url('../../../assets/caretdown.png')no-repeat;
    background-color: rgba(21, 22, 26, 0.3);
    border-radius: 5px;
    width: 150px;
    height: 30px;
    color: #FFFFFF;
    border: none;
    text-align: center;

}

select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
}

option {
    text-align: center;
    border: none;
}

option:nth-child(odd) {

    background: #1D1F23;
}

option:nth-child(even) {
    background: #22252A;
}

.btn-dropdown {
    background: transparent;
    border: none;
}

.drop-row:nth-child(even) {
    background-color: #151619;
}

.drop-row:nth-child(odd) {
    background-color: #1D1E23;
}

.drop-row img {
    width: 25px;
    height: 25px;

}

.drop-row {
    display: flex;
    column-gap: 10px;
    width: 200px;
    height: 30px;
    align-items: center;
    border: none;
    /* padding: 0 10px; */
    margin-right: 20px;
}

.drop-name {
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.drop-ENname {
    color: rgba(255, 255, 255, 0.6);
}

.dropdown-title {
    color: #FFFFFF;

}

.drop-row-search {
    background: #2F3239;
    /* border-radius: 5px; */
    display: flex;
    align-items: center;
    border: none;
}

.search {
    width: 90%;
    background: #2F3239;
    border: none;
    color: #FFFFFF;
    height: 30px;
}

.search-coins {
    width: 200px;
    height: 158px;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    border-radius: 5px;
    position: absolute;
    top: 35px;
    right: -25px;
}

.search-coins::-webkit-scrollbar {
    display: none;
}

.myInput-container {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
}

#myInput {
    background-image: url('../../../assets/search.png');
    background-position: right;
    background-repeat: no-repeat;
    width: 187px;
    height: 25px;
    font-size: 12px;
    position: sticky;
    top: 0;
    /* padding: 12px 20px 12px 40px; */
    /* border: 1px solid #ddd; */
    /* margin-bottom: 12px; */
    background-color: #2F3239;
    border-radius: 5px;
    color: white;
    padding-right: 20px;
    border: none;
    margin: 5px 0;
}

#myUL {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

#myUL li a {
    border: 1px solid #ddd;
    margin-top: -1px;
    /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block
}

#myUL li a:hover:not(.header) {
    background-color: #eee;
}

.check_btn {
    width: 40px;
    height: 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: transparent;
    border-bottom: 1px solid white;
}

@media only screen and (max-width:800px) {
    .drop-down-container {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;
    }
}
</style><style>
.v-dropdown-caller {
    width: 100%;
    display: flex !important;
    justify-content: space-around;
    background-color: rgba(21, 22, 26, 0.3);
}

.v-dropdown-container {
    border-radius: 5px;
    border: none !important;
}
</style>
