import Vue from 'vue'

// axios
import axios from 'axios'
const that = Vue.prototype

axios.defaults.baseURL = 'https://coinrace.website/api'

that.$axios = axios

export default axios
